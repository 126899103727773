@import 'src/styles/theme';

.title {
	color: $titleColor;
	font-size: 24px;
	font-weight: bold;
}

.bottomBarActions {
	display: flex;
	gap: 10px;
}

.addTestGroupBtn {
	min-width: 150px;
}
