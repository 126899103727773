@import 'src/styles/theme';
@import './segments.default.scss';

@keyframes minimize {
	from {
		transform: scale(1);
	}

	to {
		transform: scale(0.65);
	}
}

@keyframes maximize {
	from {
		transform: scale(0.65);
	}

	to {
		transform: scale(1);
	}
}

@keyframes topRightCorner {
	from {
	}

	to {
		position: absolute;
		top: 0px;
		right: 0px;
	}
}

@keyframes topLeftCorner {
	from {
		top: -2px;
		right: 0px;
	}

	to {
		top: 11px;
		left: 0px;
	}
}

.highlight {
	background-color: $primaryColorLight;
}

.wrapper {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	min-width: $segmentsWrapperMinWidth;

	.menu {
		padding: $segmentsMenuPadding;

		.list {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-direction: column;
			text-align: left;
			color: $titleColor;

			.item {
				border-radius: $segmentsItemBorderRadius;
				margin: $segmentsItemMargin;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				&:first-child {
					margin: $segmentsItemFirstChildMargin;
				}

				.children {
					font-size: $segmentsChildrenFontSize;
					padding: $segmentsChildrenPadding;
				}
			}
		}
	}

	.segmentBtn {
		padding: $segmentsSegmentBtnPadding;
		border-radius: $segmentsSegmentBtnRadius;
		display: flex;
		justify-content: space-between;

		&:hover {
			// TODO
			// background-color: $segmentsSegmentBtnBackgroundColorHover;
			// transition: background-color 0.2s ease-in-out;
		}

		&.active {
			.segmentText {
				position: absolute;
				top: $segmentsSegmentTextFromTop;
				right: $segmentsSegmentTextFromRight;
				transform: scale(0.65);
			}
		}

		&.notActive {
			position: relative;
		}

		span {
			&:nth-child(2) {
				div {
					line-height: normal;
				}
			}
		}
	}
}
