$toastMargin: var(--toastMargin, 16px 16px 0px 0px); 
$toastBackgroundColor: var(--toastBackgroundColor, white); 
$toastPadding: var(--toastPadding, 16px); 
$toastHeight: var(--toastHeight, 75px); 
$toastMaxWidth: var(--toastMaxWidth, 500px); 
$toastBorderRadius: var(--toastBorderRadius, 8px); 
$toastBoxShadow: var(--toastBoxShadow, rgba(0, 0, 0, 0.25) 0px 25px 50px -12px); 

$toastTypePadding: var(--toastTypePadding, 0px 5px 0px 5px); 
$toastMsgPadding: var(--toastMsgPadding, 0px 0px 0px 20px); 

$toastMsgFirstChildFontWeight: var(--toastMsgFirstChildFontWeight, 500); 
$toastMsgFirstChildColor: var(--toastMsgFirstChildColor, #111111); 

$toastMsgSecondChildFontWeight: var(--toastMsgSecondChildFontWeight, normal); 
$toastMsgSecondChildColor: var(--toastMsgSecondChildColor, #7c7c7c); 
$toastMsgSecondChildFontSize: var(--toastMsgSecondChildFontSize, 13px); 
$toastMsgSecondChildMargin: var(--toastMsgSecondChildMargin, 5px 0px 0px 0px); 

$toastClosePadding: var(--toastClosePadding, 5px 5px 0px 5px); 
