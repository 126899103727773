@import 'src/styles/theme';

.wrapper {
	.title {
		font-size: 34px;
		color: $titleColor;
		display: flex;
		justify-content: center;
		padding-top: 50px;
		margin-bottom: -50px;
		font-weight: bold;
	}
	.workingIcon {
		max-width: 400px;
		margin: 0 auto;
	}
}
