@import './pagebar.default.scss';

.wrapper {
	display: flex;
	flex-direction: column;
}

.content {
	display: flex;
	justify-content: center;
    flex-direction: column;
}

.title {
	color: $pageBarTitleColor;
	font-size: $pageBarTitleFontSize;
	font-weight: $pageBarTitleFontWeight;
}

.subtitle {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #667085;
}

.pagebar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: $pageBarPadding;
	border-bottom: $pageBarBorderBottom;
	margin: $pageBarMargin;
}

.pagebarTitle {
	display: flex;
	align-items: center;
}

.pagebarSubtitle {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #667085;
}


.actions {
	display: flex;
	gap: 12px;
}