@import 'src/styles/theme';

.wrapper {
	margin-bottom: 100px;

	.resourceType {
		padding: 16px;
	}

	.dropdownWrapper {
		max-width: 400px;
		margin-top: 10px;
	}

	.parentSelectWrapper {
		position: absolute;
		top: 65px;
		z-index: 4;
	}
}
