@import '@monorepo/base/src/styles/devices';

.pageWrapper {
	display: flex;
	height: 100%;
	overflow: hidden;

	.imgWrapper {
		padding: 20px;
		background-color: white;
		max-width: 60%;
		width: 100%;
		padding: 40px;

		.homeIcon {
			max-width: 500px;
			margin: 0 auto;
			padding-top: 40px;
		}
	}

	.loginWrapper {
		width: 100%;
		height: 100%;
		max-width: 40%;
		padding: 40px 20px;
		background-color: #f5f5f5;
		display: flex;
		justify-content: center;
		align-items: center;

		.loginContent {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			height: 200px;
			position: relative;
			top: -100px;

			.title {
				font-size: 22px;
				font-weight: 600;
				margin-bottom: 10px;
			}

			.subtitle {
				font-size: 14px;
				color: #868686;
				position: relative;
				margin-bottom: 30px;
				text-align: center;
			}
		}
	}
}

@media (max-width: $largeTablet) {
	.pageWrapper {
		flex-direction: column;

		.imgWrapper {
			max-width: 100%;
			padding: 20px;

			.homeIcon {
				max-width: 250px;
			}
		}

		.loginWrapper {
			max-width: 100%;
			.loginContent {
				top: -50px;
			}
		}
	}
}
