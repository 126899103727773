:root {
	--bottomBarFromLeft: 0px;
	--bottomBarFromRight: 0px;
	--bottomBarFromBottom: 0px;
	--bottomBarHeight: 75px;
	--bottomBarBackgroundColor: white;
	--bottomBarBoxShadow: 0 0 2rem 0 rgb(136 152 170 / 15%);
	--bottomBarPadding: 0 20px;
	--bottomBarGap: 10px;
	--bottomBarBorderBottom: none;
	--bottomBarBorderRight: none;
	--bottomBarBorderLeft: none;
	--bottomBarBorderTop: none;
}