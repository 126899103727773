//old wrapper - flex flex-wrap gap-6
//old pretargeting-budget - flex flex-wrap gap-6

.wrapper {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: 24px;
	padding-bottom: 1rem;

	.pretargetingBudget {
		display: flex;
		flex-direction: column;
		gap: 24px;
		width: 100%;
		max-width: 672px;
		flex-basis: 50%;
	}
}