// @import 'src/styles/theme';
// @import 'src/styles/theme/soon';
@import './soon.default.scss';

.wrapper {
	width: 100%;
	.title {
		font-size: $soonTitleFontSize;
		color: $soonTitleColor;
		display: flex;
		justify-content: center;
		padding: $soonTitlePadding;
		margin: $soonTitleMargin;
		font-weight: $soonWorkingIconFontWeight;
	}

	.workingIcon {
		max-width: $soonWorkingIconMaxWidth;
		margin: $soonWorkingIconMargin;
	}
}
