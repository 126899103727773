@import './divider.default.scss';

.wrapper {
	width: 100%;
	display: block;
	height: $dividerWrapperHeight;
	background-color: $dividerWrapperBackgroundColor;
}

.vertical {
	display: block;
	width: $dividerHorizontalWidth;
	background-color: $dividerHorizontalBackgroundColor;

	&.dotted, &.dashed {
		border-color: $dividerHorizontalBackgroundColor;
		border-width: 1px;
		width: 0px;
		background-color: transparent;
	}
}
