@import 'src/styles/theme';


.errorWrapper {
	max-width: 100%;

	.title {
		color: $titleColor;
		font-size: 24px;
		font-weight: bold;
	}

	.errorContent {
		text-wrap: wrap;
		overflow-wrap: break-word;
		max-width: 100%;
	}

}