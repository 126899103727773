@import './select.default.scss';
@import '@monorepo/base/src/styles/text/overflow';

.multipleSelection {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.selection {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		font-size: $selectionFontSize;
		padding: $selectionPadding;
		transition: 0.2s ease-in-out;
		color: $selectionColor;
		gap: $selectionGap;
		padding-right: 20px;
		cursor: pointer;

		&:hover {
			background-color: $selectionHoverBackgroundColor;
			border-radius: $selectionHoverBorderRadius;
		}

		.selectionText {
			@include text-overflow;
			max-width: 260px;
		}

		.label {
			cursor: pointer;
			width: 100%;
			text-align: left;
		}

		.activeCheckbox {
			display: block !important;
		}

		.checkbox {
			display: none;
			cursor: pointer;
			position: absolute;
			right: 20px;
		}
	}

	.activeCheckbox {
		background-color: $selectionHoverBackgroundColor;
		border-radius: $selectionHoverBorderRadius;

		.checkMark {
			cursor: pointer;
			float: right;
		}
	}

	.apply {
		width: 100%;

		.formActions {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;
			gap: 5px;
			padding: 5px 5px 5px 12px;

			.action {
				width: 33%;

				.cancelButton {
					padding: 0;
					border: none;
					background: none;
					color: $selectionColor;
					// color: #5552ee;
				}

				.cancelButton:hover {
					background-color: $listClickableBackgroundColor;
				}
			}
		}
	}
}

.list {
	max-height: $listMaxHeight;
	overflow-y: auto;
	overflow-x: hidden;

	.listItem {
		text-align: left;
		padding: $listItemPadding;
		font-size: $listItemFontSize;
		color: $listItemColor;
		display: flex;
		justify-content: space-between;
		align-items: center;

		span {
			&.listText {
				@include text-overflow;
				min-height: 16px;
			}
		}

		&.defaultValue {
			background-color: $listItemDefaultValueBackgroundColor;
			color: $listItemDefaultValueColor;

			.check {
				color: #7f56d9;
				font-size: 17px;
			}
		}
	}

	.clickable {
		cursor: pointer;

		&:hover {
			background-color: $listClickableBackgroundColor;
			border-radius: $listClickableBorderRadius;
		}
	}
}
.actions {
	margin-top: 10px;
	border-top: 1px dashed #d0d5dd;
}

.empty {
	padding: 10px;
	font-size: 14px;
	line-height: 16px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 100%;

	div {
		text-align: center;
		text-wrap: wrap;
	}
}
