@import './breadcrumb-menu.default.scss';

.wrapper {
	display: flex;

	.next {
		font-size: $breadcrumbMenuNextFontSize;
		font-weight: $breadcrumbMenuNextFontWeight;
		margin: $breadcrumbMenuNextMargin;
		color: $breadcrumbMenuNextColor;
		line-height: $breadcrumbMenuNextLineHeight;
	}

	.breadcrumbAndMenuWrapper {
		.breadcrumb {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.breadcrumbKeyValue {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				cursor: pointer;
				transition: 0.2s ease-in-out;

				&:first-child {
					padding-left: 0px;
				}

				.arrowDownWrapper {
					height: $breadcrumbMenuArrowDownWrapperHeight;

					&:hover {
						background-color: $breadcrumbMenuArrowDownHoverBackgroundColor;
					}

					&.removeHoverArrowBg {
						&:hover {
							background-color: transparent;
						}
					}

					.arrowDown {
						position: relative;
						top: $breadcrumbMenuArrowDownFromTop;
						font-size: $breadcrumbMenuArrowDownFontSize;
						color: $breadcrumbMenuArrowDownColor;
					}
				}
				.linkWrapper {
					display: flex;
					flex-direction: column;
					color: $breadcrumbMenuLinkWrapperColor;
					height: $breadcrumbMenuLinkWrapperHeight;

					&.rowDirection {
						flex-direction: row;
					}

					&:hover {
						background-color: $breadcrumbMenuLinkWrapperHoverBackgroundColor;
					}

					.breadcrumbLink {
						padding: $breadcrumbMenuLinkPadding;

						.breadcrumbKey {
							font-size: $breadcrumbMenuLinkKeyFontSize;
						}

						.breadcrumbValue {
							> span {
								font-size: $breadcrumbMenuLinkValueFontSize;
								font-weight: $breadcrumbMenuLinkValueFontWeight;

								&.error {
									color: #f97066;
								}
							}

							display: flex;
							align-items: center;
							padding: $breadcrumbMenuLinkValuePadding;
						}
					}
				}
			}
		}

		.openBreadcrumbMenu {
			position: absolute;
			top: calc(100% + 5px) !important;
			width: 100%;
			min-width: 278px;
			max-width: 320px;
		}
	}
}
