$actionLinerHeight: var(--actionLinerHeight, 40px);
$actionLinerNestedIndexLinerButtonPadding: var(--actionLinerNestedIndexLinerButtonPadding, 0px 10px 0px 5px);
$actionLinerIndexLinerButtonPadding: var(--actionLinerIndexLinerButtonPadding, 0px 8px);
$actionLinerIndexLinerButtonHoverBackgroundColor: var(--actionLinerIndexLinerButtonHoverBackgroundColor, #f3f4f6);
$actionLinerActionLinerMargin: var(--actionLinerActionLinerMargin, 0 0 10px 0);
$actionLinerSelectedLinerPadding: var(--actionLinerSelectedLinerPadding, 0 20px);
$actionLinerSelectedActionsGap: var(--actionLinerSelectedActionsGap, 15px);
$actionLinerAmountTitleFontSize: var(--actionLinerAmountTitleFontSize, 14px);
$actionLinerIndexLinerPadding: var(--actionLinerIndexLinerPadding, 0 0 0 0);
$actionLinerDynamicIndexActionsGap: var(--actionLinerDynamicIndexActionsGap, 20px);
$actionLinerSelectedLinerButtonHoverBackgroundColor: var(--actionLinerSelectedLinerButtonHoverBackgroundColor, #f3f4f6);
$actionLinerSelectedLinerBackgroundColor: var(--actionLinerSelectedLinerBackgroundColor, transparent);
$actionLinerIndexLinerButtonBorderRadius: var(--actionLinerIndexLinerButtonBorderRadius, 4px);
$actionLinerSelectedLinerBorderRadius: var(--actionLinerSelectedLinerBorderRadius, 4px);
$actionLinerSelectedLinerColor: var(--actionLinerSelectedLinerColor, #6b6b6b);