$chartTooltipTooltipBackgroundColor: var(--chartTooltipTooltipBackgroundColor, white); 
$chartTooltipTooltipPadding: var(--chartTooltipTooltipPadding, 10px 25px 10px 16px); 
$chartTooltipTooltipMinWidth: var(--chartTooltipTooltipMinWidth, 230px); 
$chartTooltipDateFontSize: var(--chartTooltipDateFontSize, 14px); 
$chartTooltipDateWeight: var(--chartTooltipDateWeight, 600); 
$chartTooltipDateBorderBottom: var(--chartTooltipDateBorderBottom, 1px solid rgba(0, 0, 0, 0.12)); 
$chartTooltipDatePadding: var(--chartTooltipDatePadding, 0 0 5px 0); 
$chartTooltipDateMargin: var(--chartTooltipDateMargin, 0 0 20px 0); 
$chartTooltipContentMargin: var(--chartTooltipContentMargin, 0 0 3px 0); 
$chartTooltipLineLabelMargin: var(--chartTooltipLineLabelMargin, 0 10px 0 0); 
$chartTooltipPointWidth: var(--chartTooltipPointWidth, 8px); 
$chartTooltipPointHeight: var(--chartTooltipPointHeight, 8px); 
$chartTooltipPointMargin: var(--chartTooltipPointMargin, 0 6px 0 0); 
$chartTooltipLabelFontSize: var(--chartTooltipLabelFontSize, 12px); 
$chartTooltipValueFontSize: var(--chartTooltipValueFontSize, 12px);
$chartTooltipValueWeight: var(--chartTooltipValueWeight, 500); 
$chartTooltipDateColor: var(--chartTooltipDateColor, #6b6b6b);