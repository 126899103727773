.wrapper {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.actions {
		height: 35px;
		width: 100%;
		padding: 0 8px;
		display: flex;
		margin: auto;
		align-items: center;
		border-radius: 3px;

		&:hover {
			background-color: 'black';
			text-decoration: none;
			cursor: pointer;
		}
	}
}