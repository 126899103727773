@import './disable.default.scss';

.is {
	pointer-events: none;
	opacity: $isOpacity;
	width: 100%;
	height: 100%;
}

.without {
	width: 100%;
	height: 100%;
}
