@import '@monorepo/base/src/styles/devices';
@import '@monorepo/base/src/styles/keyframes/opacity-in-out';
@import '@monorepo/base/src/styles/text/overflow';
@import './dropdown-new.default.scss';

.wrapper {
	display: flex;
	flex-direction: column;
	position: relative;

	.InputWrapper {
		display: flex;
		flex-direction: column;

		.label {
			font-size: 13px;
			margin: 0 0 4px;
			font-weight: 500;
			color: #344054;

			&.labelOnBorder {
				position: relative;
				top: 6px;
				left: 10px;
				background: white;
				z-index: 1;
				width: fit-content;
				font-size: 12px;
				color: #7c7c7c;
				font-weight: 400;
				padding: 0 6px;
			}
		}

		.dropdownInput {
			cursor: pointer;
			display: flex;
			align-items: center;
			position: relative;
			box-shadow: var(--inputBoxShadow, 0px 1px 2px rgba(16, 24, 40, 0.05));
			border: var(--inputBorder, 1px solid #d0d5dd);
			color: var(--inputColor, #383838);
			height: var(--inputHeight, 40px);
			border-radius: var(--inputBorderRadius, 8px);
			max-width: var(--inputWrapperMaxWidth, auto);
			outline: none;

			// &.focused {
			// 	border: $dropdownFocusBorder;
			// 	box-shadow: $dropdownFocusBoxShadow;
			// }

			&.disabled {
				background-color: #fafafa;
				cursor: not-allowed;
			}

			.defaultValues {
				width: 100%;
				max-width: 100%;
				display: flex;
				gap: 8px;
				padding: 16px;
				@include text-overflow;
				max-width: 100%;

				.defaultValue {
					padding: 5px;
					color: #344054;
					font-weight: 500;
					border-radius: 6px;
					font-size: 14px;
					display: flex;
					align-items: center;
					cursor: default;
					-webkit-user-select: none; /* Safari */
					-ms-user-select: none; /* IE 10 and IE 11 */
					user-select: none; /* Standard syntax */

					&.disabled {
						cursor: not-allowed;
					}

					.closeIcon {
						border-radius: 4px;
						width: 15px;
						height: 15px;
						margin-right: 0;
						padding: 0;
					}

					&.multi {
						border: 1px solid #d0d5dd;
					}
				}
			}

			.closeIcon {
				padding: 5px;
				margin-right: 5px;
				border-radius: 15px;
			}

			.arrowIcon {
				margin-right: 5px;

				&.disabled {
					color: $dropdownArrowIcon;
				}
			}
		}
	}

	.selectListWrapper {
		background-color: white;
		position: relative;
		width: 100%;
		z-index: 2;
		top: 6px;

		transform-origin: top;
		transition: all 0.2s ease-in-out;
		animation: opacityIn 0.2s ease-in-out;
		border: $dropdownBorder;
		border-radius: $dropdownBorderRadius;

		// .fromDropdown {
		// 	top: 30px;
		// }
	}
}
