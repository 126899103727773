@import 'src/styles/theme';

.wrapper {
  .inputWrapper {
    border: 0 !important;
    box-shadow: none !important;
    height: 18px;

    input {
      width: 60px;
    }
  }

  .spinner {
    color: $primaryColor;
  }

  .icon {
    cursor: pointer;

    span {
      color: $primaryColor;
    }
  }
}