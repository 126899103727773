@import './highlight.default.scss';

.success {
	color: $highlightSuccessColor;
}

.error {
	color: $highlightErrorColor;
}

.new {
	color: $highlightNewColor;
}

.info {
	color: $highlightInfoColor;
}
