.rowWrapper {
	display: flex;
	flex-direction: column;
	min-height: 50px;
	justify-content: space-between;

	.idTitle {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 14px;
	}

	.targetLogoSummary {
		font-weight: 500;
		font-size: 12px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		max-width: 90%;

		.tooltipWrapper {
			display: inline-block;
			.tooltip {
				-ms-transform: translateY(-25%);
				transform: translateY(-25%);
			}
		}

		.contentWrapper {
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: space-between;
			align-items: start;
			.geos {
				width: 15px;
			}
			.env {
				display: inline-block;
				font-size: 12px;
			}
			.extraData {
				width: inherit;
				background: #f2f4f7;
				mix-blend-mode: multiply;
				border-radius: 16px;
				padding: 2px 8px;
				margin-top: 5px;
			}

			.seperator {
				display: inline-block;
			}
			.deviceTypes {
				width: 18px;
				-ms-transform: translateY(10%);
				transform: translateY(10%);
			}
		}
	}
}

li:hover .extraData {
	color: white;
	background: #6366f1 !important;
}
