$httpErrorModalWrapperPadding: var(--httpErrorModalWrapperPadding, 20px); 

$httpErrorModalTitleFontSize: var(--httpErrorModalTitleFontSize, 16px); 
$httpErrorModalTitleFontWeight: var(--httpErrorModalTitleFontWeight, bold); 
$httpErrorModalTitleMargin: var(--httpErrorModalTitleMargin, 0px 0px 10px 0px);

$httpErrorModalSubtitleFontSize: var(--httpErrorModalSubtitleFontSize, 14px); 
$httpErrorModalSubtitleFontWeight: var(--httpErrorModalSubtitleFontWeight, 500); 
$httpErrorModalSubtitleMargin: var(--httpErrorModalSubtitleMargin, 0px 0px 10px 0px); 

$httpErrorModalListFontSize: var(--httpErrorModalListFontSize, 13px); 
$httpErrorModalListStyle: var(--httpErrorModalListStyle, circle); 
