@import 'src/styles/theme';

.titleWrapper {
	.title {
		color: $titleColor;
		font-size: 24px;
		font-weight: bold;
	}

	.campaignStatus {
		display: flex;
		align-items: center;
		color: $titleColor;
		font-size: 16px;
	}
}
