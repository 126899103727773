.container {
  margin-top: 16px;
}

.buttonGroup {
  > div {
    width: 100%;
    gap: 10px !important;

    > div {
      background: #eee;
      min-width: 168px !important;
    }
  }

  button {
    display: flex;
    justify-content: center;
    cursor: pointer !important;
  }
}
