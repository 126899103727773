@import 'src/styles/theme';

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 5px;
	font-size: 14px;
	max-height: 500px;
	overflow: auto;
	word-break: break-word;

	.changeWrapper {
		display: flex;
		flex-direction: column;

		.old {
			color: $dangerColor;
		}

		.new {
			color: #289327;
		}
	}
}
