@import '@monorepo/base/src/styles/keyframes/opacity-in-out';
@import './modal.default.scss';

.overlay {
	position: fixed;
	z-index: 12;
	background-color: $modalOverlayBackgroundColor;
	animation: $modalOverlayAnimation;
	opacity: 1;
	width: 100%;
	height: 100%;

	.modalWrapper {
		box-shadow: $modalWrapperBoxShadow;
		position: absolute;
		border-radius: $modalWrapperBorderRadius;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: $modalWrapperWidth;
		min-height: $modalMinHeight;
		z-index: 11;
		background-color: $modalBackgroundColor;
		display: flex;
		flex-direction: column;
	}
}
