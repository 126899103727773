@import 'src/styles/theme';

.wrapper {
	*:disabled {
		cursor: not-allowed !important;
		background-color: $disabledBackgroundColor !important;
		color: $disabledColor !important;
		opacity: 0.2;
	}

	.disabledWrapper {
		width: 100%;
		margin-right: 10px;
	}

	.textareaActions {
		display: flex;
		justify-content: flex-end;
		margin-bottom: -10px;
		margin-top: 10px;
		color: $primaryColor;
		font-size: 14px;
	}
}
