$inputMarginTop: var(--inputMarginTop, 8px);
$inputMarginBottom: var(--inputMarginBottom, 10px);
$inputBorder: var(--inputBorder, 1px solid #d0d5dd);

$selectionFontSize: var(--selectionFontSize, 14px);
$selectionPadding: var(--selectionPadding, 10px 5px 7px 12px);
$selectionGap: var(--selectionGap, 7px);
$selectionHoverBackgroundColor: var(--selectionHoverBackgroundColor, #f8f8f8);
$selectionHoverBorderRadius: var(--selectionHoverBorderRadius, 5px);
$selectionCheckboxAccentColor: var(--selectionCheckboxAccentColor, #4f46e5);
$selectionColor: var(--selectionMultiColor, #6b6b6b);

$listMaxHeight: var(--listMaxHeight, 430px);
$listItemPadding: var(--listItemPadding, 10px 12px 7px 12px);
$listItemFontSize: var(--listItemFontSize, 14px);
$listItemColor: var(--listItemColor, #101828);
$listItemDefaultValueBackgroundColor: var(--listItemDefaultValueBackgroundColor, #f9fafb);
$listItemDefaultValueColor: var(--listItemDefaultValueColor, #101828);
$listClickableBackgroundColor: var(--listClickableBackgroundColor, #f3f4f6);
$listClickableBorderRadius: var(--listClickableBorderRadius, 0px);