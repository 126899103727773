.singleOptionWrapper {
	display: flex;
	flex-direction: row;
	transition: 100ms all linear;
	gap: 10px;

	.left {
		flex-grow: 0;
		align-self: center;
	}

	.right {
		flex-grow: 1;

		.optionName {
		}

		.optionDescription {
			font-size: 12px;
			color: #6b6b6b;
		}
	}
}
