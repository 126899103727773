$toggleTitleFontSize: var(--toggleTitleFontSize, 14px);
$toggleTitleMargin: var(--toggleTitleMargin, 0px 0px 5px 0px);

$toggleSwitchWidth: var(--toggleSwitchWidth, calc(30px * 1.2));
$toggleSwitchHeight: var(--toggleSwitchHeight, calc(17px * 1.2));
$toggleSwitchMargin: var(--toggleSwitchMargin, 0px 10px 0px 10px);

$toggleSliderBorderRadius: var(--toggleSliderBorderRadius, 30px);
$toggleSliderFocusBoxShadow: var(--toggleSliderFocusBoxShadow, 0 0 1px #6366f1);
$toggleSliderDefaultBackgroundColor: var(--toggleSliderDefaultBackgroundColor, #D0D5DD);
$toggleSliderDefaultHoverBackgroundColor: var(--toggleSliderDefaultHoverBackgroundColor, #bec4ce);
$toggleSliderActiveBackgroundColor: var(--toggleSliderActiveBackgroundColor, #6366f1);
$toggleSliderActiveHoverBackgroundColor: var(--toggleSliderActiveHoverBackgroundColor, #4f46e5);


$toggleLabelColor: var(--toggleLabelColor, #7c7c7c);
$toggleLabelFontSize: var(--toggleLabelFontSize, 14px);
$toggleRelativeSize: 1.2;