$segmentsWrapperMinWidth: var(--segmentsWrapperMinWidth, 80px);
$segmentsMenuPadding: var(--segmentsMenuPadding, 0px);
$segmentsItemBorderRadius: var(--segmentsItemBorderRadius, 2px);
$segmentsItemMargin: var(--segmentsItemMargin, 0 0 0 0);
$segmentsItemFirstChildMargin: var(--segmentsItemFirstChildMargin, 0 0 0 0);
$segmentsChildrenFontSize: var(--segmentsChildrenFontSize, 14px);
$segmentsChildrenPadding: var(--segmentsChildrenPadding, 8px 5px 8px 20px);
$segmentsSegmentBtnRadius: var(--segmentsSegmentBtnRadius, 4px);
$segmentsSegmentBtnBackgroundColorHover: var(--segmentsSegmentBtnBackgroundColorHover, #f3f4f6);
$segmentsSegmentBtnPadding: var(--segmentsSegmentBtnPadding, 0px 8px);
$segmentsSegmentTextFromTop: var(--segmentsSegmentTextFromTop, 0px);
$segmentsSegmentTextFromRight: var(--segmentsSegmentTextFromRight, 0px);