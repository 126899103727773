@import 'src/styles/theme';

@import './creative-preview.default.scss';

.creativePreview {
	max-width: $creativePreviewMaxWidth;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 3px;
}

.copyIcon {
	cursor: pointer;
	color: rgb(107, 107, 107);
	padding: 3px;
	border-radius: 3px;
	transition: color 0.3s ease;

	&.clicked {
		color: rgb(207, 207, 207);
	}
}