$cardBackgroundColor: var(--cardBackgroundColor, white);
$cardMaxWidth: var(--cardMaxWidth, 52em);
$cardBorderRadius: var(--cardBorderRadius, 0.375rem);

$cardBorderPadding: var(--cardBorderPadding, 0.75rem 1rem 0.75rem 1rem);
$cardBorderWidth: var(--cardBorderWidth, 1px);
$cardBorderColor: var(--cardBorderColor, #e5e7eb);
$cardBorderStyle: var(--cardBorderStyle, solid);

$cardTitleFontSize: var(--cardTitleFontSize, 16px);
$cardTitleFontWeight: var(--cardTitleFontWeight, 500);
$cardTitleMargin: var(--cardTitleMargin, 0);
$cardTitleColor: var(--cardTitleColor, #6b6b6b);

$cardTitleIconMargin: var(--cardTitleIconMargin, 0px 8px 0px 0px);