@import './pagination.default.scss';
@import '../table.default.scss';
@import '../table-info/table-info.default.scss';
@import '@monorepo/base/src/styles/resets/button';

@mixin paginationButton {
	cursor: pointer;
	padding: $paginationPaginationButtonPadding;
	border-radius: $paginationPaginationButtonBorderRadius;
	background-color: white;
	font-size: $paginationPaginationButtonFontSize;
	display: flex;
	width: $paginationPaginationButtonWidth;
	height: $paginationPaginationButtonHeight;
	margin: $paginationPaginationButtonMargin;
	padding: $paginationPaginationButtonPadding;
	align-items: center;
	justify-content: center;
	color: $paginationPaginationButtonColor;
	border: $paginationPaginationButtonBorder;
	background-color: $paginationPaginationButtonBackgroundColor;
	transition: background-color 0.15s ease-in-out;

	&:hover {
		background-color: $paginationPaginationButtonHoverBackgroundColor;
	}
}

.pagination {
	display: flex;
	align-items: center;
	font-size: $tableInfoWrapperFontSize;
	color: $tableBodyTextColor;
	gap: 10px;

	.info {
		white-space: pre;
	}

	button {
		@include resetBtn;
		@include paginationButton;
	}

	.disable {
		opacity: 0.5;
		pointer-events: none;
	}

	.next,
	.prev,
	.end,
	.start {
		@include paginationButton;
	}

	.start,
	.end {
		font-size: $paginationEndFontSize;
	}
}
