$tooltipFromBottom: var(--tooltipFromBottom, calc(100% + 10px));
$tooltipMaxWidth: var(--tooltipMaxWidth, 400px);
$tooltipPadding: var(--tooltipPadding, 8px 12px 10px 8px);
$tooltipBorderRadius: var(--tooltipBorderRadius, 6px);
$tooltipFromLeft: var(--tooltipFromLeft, 50%);
$tooltipLineHeight: var(--tooltipLineHeight, 15px);
$tooltipFontWeight: var(--tooltipFontWeight, 500);
$tooltipFontSize: var(--tooltipFontSize, 12px);

$tooltipArrowAfterFromTop: var(--tooltipArrowAfterFromTop, 100%);
$tooltipArrowAfterFromLeft: var(--tooltipArrowAfterFromLeft, 50%);
$tooltipArrowAfterMargin: var(--tooltipArrowAfterMargin, 0px 0px 0px -5px);
$tooltipArrowAfterBorder: var(--tooltipArrowAfterBorder, 5px solid);

$tooltipAlignToRightFromRight: var(--tooltipAlignToRightFromRight, -20px);
$tooltipAlignToRightAfterFromRight: var(--tooltipAlignToRightAfterFromRight, 25px);

$tooltipAlignToLeftFromLeft: var(--tooltipAlignToLeftFromLeft, -20px);
$tooltipAlignToLeftAfterFromLeft: var(--tooltipAlignToLeftAfterFromLeft, 25px);

$tooltipLightBackgroundColor: var(--tooltipLightBackgroundColor, #fff);
$tooltipLightColor: var(--tooltipLightColor, #414141);
$tooltipLightBoxShadow: var(--tooltipLightBoxShadow,
  rgb(0 0 0 / 19%) 0px 10px 20px,
  rgb(0 0 0 / 23%) 0px 6px 6px);
$tooltipLightArrowAfterBorderColor: var(--tooltipLightArrowAfterBorderColor,
  #fff transparent transparent transparent);

$tooltipDarkBackgroundColor: var(--tooltipDarkBackgroundColor, #414141);
$tooltipDarkColor: var(--tooltipDarkColor, white);
$tooltipDarkBoxShadow: var(--tooltipDarkBoxShadow,
  0px 12px 16px -4px rgba(16, 24, 40, 0.1),
  0px 4px 6px -2px rgba(16, 24, 40, 0.05));
$tooltipDarkArrowAfterBorderColor: var(--tooltipDarkArrowAfterBorderColor,
  #414141 transparent transparent transparent);