$controlledSidebarWrapperPadding: var(--controlledSidebarWrapperPadding, 16px 0px 20px 0px);
$controlledSidebarTogglerHeight: var(--controlledSidebarTogglerHeight, 18px);
$controlledSidebarTogglerPadding: var(--controlledSidebarTogglerPadding, 0px 20px);

$controlledSidebarFullListItemTitleMargin: var(--controlledSidebarFullListItemTitleMargin, 0px 0px 0px 10px);

$controlledListMargin: var(--controlledListMargin, 25px 0px 0px 0px);
$controlledListItemWrapperWidth: var(--controlledListItemWrapperWidth, calc(100% - 5px));
$controlledListItemWrapperHeight: var(--controlledListItemWrapperHeight, 38px);
$controlledListItemWrapperLineHeight: var(--controlledListItemWrapperLineHeight, 38px);
$controlledListItemWrapperFontSize: var(--controlledListItemWrapperFontSize, 14px);
$controlledListItemWrapperPadding: var(--controlledListItemWrapperPadding, 0px 0px 0px 15px);
$controlledListItemWrapperMargin: var(--controlledListItemWrapperMargin, 0px 0px 10px 0px);
$controlledListItemWrapperBorderRadius: var(--controlledListItemWrapperBorderRadius, 0px 10px 10px 0px);
$controlledListItemWrapperHoverBackgroundColor: var(--controlledListItemWrapperHoverBackgroundColor, #f8f8f8);

$controlledListItemWrapperActiveItemBackgroundColor: var(--controlledListItemWrapperActiveItemBackgroundColor, #6366f1);
$controlledListItemWrapperActiveItemBorderRadius: var(--controlledListItemWrapperActiveItemBorderRadius, 0px 10px 10px 0px);
$controlledListItemWrapperActiveItemHoverBackgroundColor: var(--controlledListItemWrapperActiveItemHoverBackgroundColor, #6366f1);
$controlledListItemWrapperActiveItemLinkColor: var(--controlledListItemWrapperActiveItemLinkColor, white);
