@import './card.default.scss';

.wrapper {
	width: 100%;
	background-color: $cardBackgroundColor;
	max-width: $cardMaxWidth;
	border-radius: $cardBorderRadius;


	&.border {
		padding: $cardBorderPadding;
		border-width: $cardBorderWidth;
		border-color: $cardBorderColor;
		border-style: $cardBorderStyle;
	}

	&.full {
		max-width: none;
	}

	.clickable {
		cursor: pointer;
	}

	.title,
	.titleContent {
		display: flex;
		align-items: center;
		font-weight: $cardTitleFontWeight;
		font-size: $cardTitleFontSize;
		color: $cardTitleColor;
		margin: $cardTitleMargin;

		.titleIcon {
			margin: $cardTitleIconMargin;
		}

		.collapseIcon {
			padding: .2rem;
		}
	}

	.titleChildren {
		margin-left: auto;
	}
}