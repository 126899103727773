.wrapper {
	display: flex;
	width: 100%;
	column-gap: 30px;

	.dropdown {
		width: 400px;
	}

	.sharedAmountWrapper {
		margin-top: 12px;
		.sharedAmountTooltip {
			margin-right: 25px;
			.sharedText {
				cursor: default;
				font-size: 13px;
				font-weight: 500;
			}
		}
	}
}
