@import './dater.default.scss';


.dateWrapper {
	padding-top: 10px;
}

.btnWrapper {
	display: flex;
	align-items: center;

	.label {
		font-size: $controlledDaterLabelFontSize;
		font-weight: $controlledDaterLabelFontWeight;
		color: $controlledDaterLabelColor;
		white-space: pre;
	}

	.btn {
		margin: $controlledDaterBtnMargin;
		border-color: $controlledDaterBtnBorderColor;
		color: $controlledDaterBtnColor;

		&:hover {
			background-color: $controlledDaterBtnHoverColor;
		}
	}

	.calendarIcon {
		>path {
			stroke: $controlledDaterIconColor;
		}
	}
}

.actions {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	font-size: $controlledDaterActionsFontSize;
	margin-right: 16px;

	.cancelBtn {
		width: 67px;
		height: 40px;
		padding: 10px;
		border-radius: $controlledDaterCancelBtnHoverBorderRadius;

		&:hover {
			background-color: $controlledDaterCancelBtnHoverBackgroundColor;
		}
	}

	.applyBtn {
		width: 72px;
		height: 40px;
		border-radius: 8px;
	}
}