$rotateIconWidth: var(--rotateIconWidth, 20px);
$rotateIconHeight: var(--rotateIconHeight, 20px);
$iconToTheLeftPaddingRight: var(--iconToTheLeftPaddingRight, 6px);
$iconToTheRightPaddingLeft: var(--iconToTheRightPaddingLeft, 6px);
$disabledOpacity: var(--disabledOpacity, 0.5);

$textButtonFontSize: var(--textButtonFontSize, 14px);
$textButtonFontWeight: var(--textButtonFontWeight, 400);
$textButtonLineHeight: var(--textButtonLineHeight, 28px);

$primaryTextButtonColor: var(--primaryTextButtonColor, #6941c6);
$primaryTextButtonHeight: var(--primaryTextButtonHeight, 100%);
$primaryTextButtonWidth: var(--primaryTextButtonWidth, 100%);

$secondaryTextButtonColor: var(--secondaryTextButtonColor, #101828);
$secondaryTextButtonHeight: var(--secondaryTextButtonHeight, 100%);
$secondaryTextButtonWidth: var(--secondaryTextButtonWidth, 100%);
$secondayTextHoverBackgroundColor: var(--secondayTextHoverBackgroundColor, #f3f4f6);

$secondaryButtonHeight: var(--secondaryButtonHeight, 40px);
$secondaryButtonFontSize: var(--secondaryButtonFontSize, 14px);
$secondaryButtonFontWeight: var(--secondaryButtonFontWeight, 600);
$secondaryButtonBackgroundColor: var(--secondaryButtonBackgroundColor, #ffffff);
$secondaryButtonPadding: var(--secondaryButtonPadding, 6px 14px);
$secondaryButtonBorderRadius: var(--secondaryButtonBorderRadius, 8px);
$secondaryButtonBorder: var(--secondaryButtonBorder, 1px solid #d0d5dd);
$secondaryButtonColor: var(--secondaryButtonColor, #344054);
$secondaryButtonHoverBackgroundColor: var(--secondaryButtonHoverBackgroundColor, #f9fafb);
$secondaryButtonHoverColor: var(--secondaryButtonHoverColor, #344054);
$secondaryButtonLineHeight: var(--secondaryButtonLineHeight, 20px);

$primaryButtonHeight: var(--primaryButtonHeight, 40px);
$primaryButtonFontSize: var(--primaryButtonFontSize, 14px);
$primaryButtonFontWeight: var(--primaryButtonFontWeight, 600);
$primaryButtonBackgroundColor: var(--primaryButtonBackgroundColor, #6366f1); // #7F56D9
$primaryButtonPadding: var(--primaryButtonPadding, 6px 14px);
$primaryButtonBorderRadius: var(--primaryButtonBorderRadius, 8px);
$primaryButtonBorder: var(--primaryButtonBorder, 1px solid #6366f1);
$primaryButtonColor: var(--primaryButtonColor, #ffffff);
$primaryButtonHoverBackgroundColor: var(--primaryButtonHoverBackgroundColor, #423ff0); //#6941C6
$primaryButtonHoverColor: var(--primaryButtonHoverColor, #ffffff);
$primaryButtonLineHeight: var(--primaryButtonLineHeight, 20px);