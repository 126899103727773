@import 'src/styles/theme';

.paddingFromTop {
	margin-top: -40px;
}

.title {
	color: $titleColor;
	font-size: 24px;
	font-weight: bold;
}
