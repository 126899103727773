$fileSelectorWrapperMargin: var(--fileSelectorWrapperMargin, 25px 0px 0px 0px); 
$fileSelectorWrapperMaxHeight: var(--fileSelectorWrapperMaxHeight, 900px); 
$fileSelectorWrapperPadding: var(--fileSelectorWrapperPadding, 5px 0); 

$fileSelectorLabelColor: var(--fileSelectorLabelColor, #6b6b6b); 
$fileSelectorLabelBoxShadow: var(--fileSelectorLabelBoxShadow, 0px 1px 2px rgba(16, 24, 40, 0.05)); 
$fileSelectorLabelBorderRadius: var(--fileSelectorLabelBorderRadius, 4px); 
$fileSelectorLabelPadding: var(--fileSelectorLabelPadding, 12px); 
$fileSelectorLabelFontSize: var(--fileSelectorLabelFontSize, 14px);
$fileSelectorLabelBorder: var(--fileSelectorLabelBorder, 1px solid #9ca3af); 
$fileSelectorLabelBackgroundColor: var(--fileSelectorLabelBackgroundColor, #f8f8f8); 

$fileSelectorLabelHoverBackgroundColor: var(--fileSelectorLabelHoverBackgroundColor, #e2e2ff80); 
$fileSelectorLabelHoverColor: var(--fileSelectorLabelHoverColor, #6366f1); 