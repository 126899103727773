.rdrDefinedRangesWrapper {
}

.rdrStaticRanges {
	display: flex;
	flex-direction: column;
}

.rdrStaticRange {
	font-size: inherit;
}

.rdrStaticRangeLabel {
}

.rdrInputRanges {
}

.rdrInputRange {
	display: flex;
}

.rdrInputRangeInput {
}
