@import './selection-list.default.scss';

.selectionListAndError {
	width: 100%;
	height: 100%;
}

.selectionListWrapper {
	min-height: $selectionListWrapperMinHeight;
	cursor: pointer;
	position: relative;
	border: $selectionListBorder;
	// margin: $selectionListMargin;
	border-radius: $selectionListBorderRadius;

	.activeSingleOption {
		margin: 5px;
	}

	&.closed {
		display: flex;
		flex-wrap: wrap;
		gap: $selectionListClosedGap;
		padding: $selectionListClosedPadding;
		border-radius: $selectionListClosedBorderRadius;

		.closeIcon {
			font-size: $selectionListClosedCloseIconFontSize;
		}
	}

	.title {
		font-size: $selectionListTitleFontSize;
		color: $selectionListTitleColor;
		position: absolute;

		&.empty {
			top: $selectionListTitleEmptyFromTop;
			left: $selectionListTitleEmptyFromLeft;
		}

		&.notEmpty {
			top: $selectionListTitleNotEmptyFromTop;
			left: $selectionListTitleNotEmptyFromLeft;

			.labelActiveOptions {
				font-size: $selectionListTitleNotEmptyLabelActiveOptionsFontSize;
				font-weight: $selectionListTitleNotEmptyLabelActiveOptionsFontWeight;
			}
		}
	}

	.toggleArrow {
		position: absolute;
		top: $selectionListToggleArrowFromTop;
		right: $selectionListToggleArrowFromRight;

		&.hover {
			background-color: $selectionListToggleArrowHoverBackgrounColor;
		}
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	background-color: $wrapperBackgroundColor;
	border-radius: $wrapperBorderRadius;

	.skeletonWrapper {
		padding: $skeletonWrapperPadding;
		width: 100%;
	}

	.searchWrapper {
		margin: $searchWrapperMargin;

		.searchbar {
			outline: none;
			width: 100%;
			margin: $searchBarMargin;

			.searchInputWrapper {
				border: none;
				box-shadow: none;
				outline: none;
				display: block;

				.searchInput {
					border: none;
					box-shadow: none;
					outline: none;
					min-width: 100%;
				}
			}
		}
	}

	.addLayer {
		position: absolute;
		border-radius: $addLayerBorderRadius;
		width: 100%;
		top: $addLayerFromTop;
		left: $addLayerFromLeft;
		z-index: 1;
		background-color: $addLayerFromBackgroundColor;
		box-sizing: content-box;
		border: $addLayerBorder;
	}

	.sumWrapper {
		padding: $sumWrapperPadding;
		color: $sumWrapperColor;
		font-size: $sumWrapperFontSize;
	}

	.optionsWrapper {
		overflow-y: auto;
		overflow-x: hidden;
		height: $optionsWrapperHeight;
		user-select: none;

		&.decreasedHeight {
			height: $optionsWrapperDecreasedHeight;
		}

		li {
			padding: $optionsWrapperOptionPadding;
			margin: $optionsWrapperOptionMargin;
			cursor: pointer;

			&:hover {
				background-color: $optionsWrapperOptionHoverBackgroundColor;
			}

			&:active {
				background-color: $optionsWrapperOptionActiveBackgroundColor;
			}

			&.highlight {
				background-color: $optionsWrapperOptionHighlightBackgroundColor;
			}

			&.disabled {
				cursor: not-allowed;
				color: #888;
				opacity: 0.5;

				&:hover {
					background-color: transparent;
				}
			}
		}
	}

	.resultsCounter {
		padding: $resultsCounterPadding;
		color: $resultsCounterColor;
		font-size: $resultsCounterFontSize;
	}

	.noResults {
		padding: $noResultsPadding;
		color: $noResultsColor;
		font-size: $noResultsFontSize;
		height: $noResultsHeight;
	}

	&.selectionListError {
		border: $selectionListErrorBorder;
	}

	&.active {
		border: $selectionListActiveBorder;

		.label {
			opacity: 1;
		}
	}

	&.disabled {
		.label {
			color: #d0d5dd;
		}

		.selectionList {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}

	.label {
		position: absolute;
		top: $selectionListLabelFromTop;
		left: $selectionListLabelFromLeft;
		font-size: $selectionListLabelFontSize;
		background: $selectionListLabelBackgroundColor;
		padding: $selectionListLabelPadding;
		box-sizing: border-box;
		color: $selectionListLabelColor;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
		outline: none;
	}
}
