@import './filter-entity-menu.default.scss';

.wrapper {
	// overflow: hidden;
	padding: 12px;
	font-size: 14px;
	font-weight: $filterEntityMenuFontWeight;

	.closeButton {
		float: right;
	}

	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: $filterEntityMenuTitleColor;
		text-align: left;
		font-size: 16px;
		padding-bottom: 25px;
	}

	.actions {
		display: flex;
		margin-top: 10px;
		align-items: center;
		justify-content: flex-end;
		gap: 5px;

		.action {
			width: 33%;
			justify-content: flex-end;
			.cancelButton {
				padding: 0;
				border: none;
				background: none;
				color: #5552ee;
			}

			.cancelButton:hover {
				background-color: $menuHoverColor;
			}
		}
	}

	.content {
		padding-top: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		gap: 10px;

		.dropdown {
			margin: 0;
		}
	}
}

.limitEnumSize {
	overflow-y: auto;
	max-height: 165px;
}
