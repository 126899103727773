$gButtonMaxWidth: var(--gButtonMaxWidth, 194px);
$gButtonHeight: var(--gButtonHeight, 194px);
$gButtonBackgroundColor: var(--gButtonBackgroundColor, transparent);
$gButtonColor: var(--gButtonColor, #6b6b6b);
$gButtonFontSize: var(--gButtonFontSize, 13px);
$gButtonBorderRadius: var(--gButtonBorderRadius, 8px);
$gButtonMargin: var(--gButtonMargin, 0px);
$gButtonBorder: var(--gButtonBorder, none);
$gButtonFlexDirection: var(--gButtonFlexDirection, row);

$gButtonHoverBackgroundColor: var(--gButtonHoverBackgroundColor, transparent);
$gButtonHoverColor: var(--gButtonHoverColor, #6366f1);

$gButtonActiveBackgroundColor: var(--gButtonActiveBackgroundColor, transparent);
$gButtonActiveColor: var(--gButtonActiveColor, #6366f1);
$gButtonActiveBorder: var(--gButtonActiveBorder, 1px solid #7f56d9);
$gButtonDisabledBorder: var(--gButtonDisabledBorder, 1px solid #d0d5dd);

$gButtonErrorBorder: var(--gButtonErrorBorder, 1px solid #fb6340);

$gButtonTextMargin: var(--gButtonTextMargin, 0px);
$gButtonTextColor: var(--gButtonTextColor, #344054);

$gButtonIconSize: var(--gButtonIconSize, 44px);
$gButtonIconColor: var(--gButtonIconColor, #6b6b6b);
$gButtonIconActiveColor: var(--gButtonActiveIconColor, #6366f1);