@import './file-selector.default.scss';
@import '@monorepo/base/src/styles/text/overflow';

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.baseButton {
	cursor: pointer;
	white-space: pre;
}

.wrapper {
	position: relative;
	max-height: 900px;
	max-width: 300px;

	.icon {
		margin-right: 7px;
		font-size: 18px;

		&.rotateIcon {
			width: 20px;
			height: 20px;
			transform-origin: center;
			animation: rotate 1s ease-in-out infinite;
		}
	}

	.label {
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		transition-duration: 200ms;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		outline: none;
		transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
		cursor: pointer;
		height: 40px;
		min-width: 100px;
		color: $fileSelectorLabelColor;
		box-shadow: $fileSelectorLabelBoxShadow;
		border-radius: $fileSelectorLabelBorderRadius;
		padding: $fileSelectorLabelPadding;
		font-size: $fileSelectorLabelFontSize;
		border: $fileSelectorLabelBorder;
		background-color: $fileSelectorLabelBackgroundColor;

		.input {
			display: none;
		}

		&:hover {
			background-color: $fileSelectorLabelHoverBackgroundColor;
			color: $fileSelectorLabelHoverColor;
		}
	}

	// .error {
	// 	color: $errorColor;
	// 	font-size: 12px;
	// 	line-height: 15px;
	// 	opacity: 0;
	// 	height: 0px;
	// 	transition: all 0.2s ease-in-out;
	// 	margin-top: 15px;

	// 	&.show {
	// 		opacity: 1;
	// 		height: 100%;
	// 		margin-top: 5px;
	// 		padding-top: 10px;
	// 		padding-left: 3px;
	// 	}
	// }
}