@import 'src/styles/theme';

.wrapper {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	height: 760px;

	&.loading {
		height: 300px;
	}

	.statusLimitsList {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		gap: 1rem;
	}

	.spinner {
		color: $primaryColor;
	}

	.gridWrapper {
		& > div {
			width: 100%;
		}
	}
}