@keyframes logoIn {
	0% {
		opacity: 0.6;
		transform: scale(0.9);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.logoWrapper {
	display: flex;
	opacity: 1;
	transform-origin: left;
	// animation: logoIn 0.1s 0.1s ease-out forwards;
	line-height: 22px;
	> span {
		font-size: 22px;
		font-weight: bold;
		padding-left: 5px;
	}
}
