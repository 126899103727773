@import '../theme.scss';

:root {
	--dropdownWrapperMaxHeight: 44px;
	--dropdownWrapperColor: #474747;
	--dropdownWrapperMargin: 30px 0px 0px 0px;
	--dropdownArrowIcon: #344054;
	--dropdownActiveBorder: 1px solid #d6bbfb;
	--dropdownActiveBoxShadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #f4ebff;
	--dropdownActiveLabelOpacity: 1;
	--dropdownWrapperDisabledBackgroundColor: white;
	--dropdownDisabledOpacity: 0.5;
	--dropdownLabelOpacity: 1;
	--dropdownFocusBorder: none;
	--dropdownFocusBoxShadow: none;
	--dropdownOptionsFromTop: calc(100% + 2px);
	--dropdownOptionsBorderRadius: 4px;
	--dropdownOptionHighlightBackgroundColor: #f0f9ff;
	--dropdownDropdownBarDisabledColor: #344054;
	--dropdownOptionActiveDisabledColor: #344054;
	--dropdownOptionActiveArrowDisabledColor: #98a2b3;
	--dropdownOptionsMaxHeight: 130px;
	--dropdownWrapperDisabledBorder: 1px solid #d0d5dd;
}
