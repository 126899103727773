@import 'src/styles/theme';
@import './table-of-contents.default.scss';

.wrapper {
	padding: 0px 5px 5px 0px;

	&.divider {
		border-top: 1px solid rgba(0, 0, 0, 0.12);
	}

	.title {
		width: 100%;
		font-size: 14px;
		font-weight: 500;
		padding: 5px 5px 5px 30px;
		color: $tocTitleColor;
	}

	.list {
		.item {
			// cursor: pointer;
			font-size: $tocItemFontSize;
			font-weight: $tocItemFontWeight;
			margin: $tocItemMargin;
			transition: background-color 0.2s ease-in-out;
			border-radius: $tocItemBorderRadius;
			color: $tocItemTitleColor;
			width: 100%;

			&:hover {
				background-color: $tocItemTitleHoverBackgroundColor;
			}

			&:first-child {
				margin-top: 5px;
			}

			&.activeRoute {
				background-color: $tocActiveRouteBackgroundColor;
				color: $tocActiveRouteColor;
			}

			.link {
				cursor: pointer;
				padding: $tocLinkPadding;
				display: block;
				height: 100%;
				width: 100%;

				&.active {
					color: $tocLinkActiveColor;
					font-weight: $tocLinkActiveFontWeight;

					&::before {
						margin: $tocLinkActiveBeforeMargin;
						position: absolute;
						content: $tocLinkActiveBeforeContent;
						color: $tocLinkActiveBeforeColor;
					}
				}
			}
		}
	}
}