@import 'src/styles/theme';
@import './sider-slider.default.scss';

@keyframes slideInRight {
	from {
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideOutRight {
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		transform: translate3d(100%, 0, 0);
		visibility: visible;
	}
}

@keyframes opacityIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes opacityOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}


.greyScreen {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 11;
	background-color: $siderSliderGreyScreenMaxWidth;

	&.noGreyScreen {
		width: 0;
	}

	&.open {
		animation: opacityIn 0.3s ease-out forwards;
	}
	&.close {
		animation: opacityOut 0.3s ease-out forwards;
	}

	.wrapper {
		position: fixed;
		height: 100%;
		max-width: $siderSliderWrapperMaxWidth;
		width: $siderSliderWrapperWidth;
		background-color: $siderSliderWrapperBackgroundColor;
		z-index: 10;
		display: flex;
		flex-direction: column;
		align-items: center;
		top: $siderSliderWrapperFromTop;
		border-radius: 2px 0px 0px 2px;
		border: $siderSliderWrapperBorder;
		overflow: auto;

		&.right {
			right: 0;
		}

		&.left {
			left: 0;
		}

		&.open {
			box-shadow: 0 0 2rem 0 rgba(209, 209, 209, 0.3);
			animation: slideInRight 0.3s ease-out forwards, opacityIn 0.3s ease-out forwards;
		}

		&.close {
			animation: slideOutRight 0.3s ease-out forwards, opacityOut 0.3s ease-out forwards;

			&.disableAnimation {
				animation: none !important;
				transform: translate3d(100%, 0, 0);
				visibility: visible;
			}
		}

		.closeIcon {
			position: relative;
			color: $siderSliderCloseIconColor;
			font-size: $siderSliderCloseIconFontSize;
			width: $siderSliderCloseIconWidth;
			cursor: pointer;
			margin: $siderSliderCloseIconMargin;
			padding: $siderSliderCloseIconPadding;
			transition: 0.2s;
			z-index: 1;

			&:hover {
				transition: 0.2s;
			}
		}
	}
}
