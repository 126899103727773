@import '@monorepo/base/src/styles/keyframes/opacity-in-out';
@import 'src/styles/theme';

.loadingPage {
	cursor: wait;
	pointer-events: none;
	opacity: 0.4;
}

.title {
	color: $titleColor;
	font-size: 24px;
	font-weight: bold;
}
