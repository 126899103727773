@import './form-error.default.scss';

.error {
	color: $formErrorColor;
	font-size: $formErrorFontSize;
	line-height: $formErrorLineHeight;
	opacity: $formErrorOpacity;
	height: $formErrorHeight;
	transition: all 0.2s ease-in-out;

	&.show {
		opacity: $formErrorShowOpacity;
		margin: $formErrorShowMargin;
		padding: $formErrorPadding;
	}
}
