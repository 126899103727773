$chartLegendLegendGap: var(--chartLegendLegendGap, 10px);
$chartLegendLegendPadding: var(--chartLegendLegendPadding, 0px 60px 12px 60px);
$chartLegendLegendFromTop: var(--chartLegendLegendFromTop, -4px);
$chartLegendItemWrapper: var(--chartLegendItemWrapper, 60px);
$chartLegendItemWrapperMargin: var(--chartLegendItemWrapperMargin, 0 60px 0 0);
$chartLegendMarkerMargin: var(--chartLegendMarkerMargin, 3px 0 0 0);
$chartLegendMarkerHeight: var(--chartLegendMarkerHeight, 2px);
$chartLegendContentHeight: var(--chartLegendContentHeight, 45px);
$chartLegendLabelWrapperPadding: var(--chartLegendLabelWrapperPadding, 4px 4px 6px 4px);
$chartLegendLabelWrapperHoverBackgroundColor: var(--chartLegendLabelWrapperHoverBackgroundColor, #f3f4f6);
$chartLegendLabelFontSize: var(--chartLegendLabelFontSize, 13px);
$chartLegendLabelMargin: var(--chartLegendLabelMargin, 0 13px 0 0);
$chartLegendSumFontSize: var(--chartLegendSumFontSize, 16px);
$chartLegendSumLineHeight: var(--chartLegendSumLineHeight, 10px);
$chartLegendSumPadding: var(--chartLegendSumPadding, 5px 0 0 0);
$chartLegendArrowPadding: var(--chartLegendArrowPadding, 2px 0 0 0);
$chartLegendLabelColor: var(--chartLegendLabelColor, #6b6b6b);