.rdrDateInput {
	position: relative;

	input {
		outline: none;
	}

	.rdrWarning {
		position: absolute;
		font-size: 1.6em;
		line-height: 1.6em;
		top: 0;
		right: 0.25em;
		color: #ff0000;
	}
}
