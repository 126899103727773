$bottomBarFromLeft: var(--bottomBarFromLeft, 0px);
$bottomBarFromRight: var(--bottomBarFromRight, 0px);
$bottomBarFromBottom: var(--bottomBarFromBottom, 100);

$bottomBarHeight: var(--bottomBarHeight, 72px);
$bottomBarBackgroundColor: var(--bottomBarBackgroundColor, white);
$bottomBarBoxShadow: var(--bottomBarBoxShadow, none);
$bottomBarPadding: var(--bottomBarPadding, 0 20px);
$bottomBarGap: var(--bottomBarGap, 10px);
$bottomBarBorderBottom: var(--bottomBarBorderBottom, 1px solid #eaecf0);
$bottomBarBorderRight: var(--bottomBarBorderRight, none);
$bottomBarBorderLeft: var(--bottomBarBorderLeft, none);
$bottomBarBorderTop: var(--bottomBarBorderTop, none);
$bottomBarPosition: var(--bottomBarPosition, fixed);
$bottomBarBorderTop: var(--bottomBarBorderTop, 1px solid #EAECF0);