@import './menu.default.scss';

@keyframes openMenu {
	0% {
		visibility: hidden;
		opacity: 0;
	}

	100% {
		visibility: visible;
		opacity: 1;
	}
}

@keyframes closeMenu {
	0% {
		visibility: visible;
		opacity: 1;
	}

	100% {
		visibility: hidden;
		opacity: 0;
	}
}

.wrapper {
	position: relative;
	z-index: 9;
	align-self: flex-end;

	&.open {
		animation: openMenu 0.2s ease-in-out forwards;

		.menu {
			visibility: visible;

			// &.breadCrumbMenu {
			// 	width: 100%;
			// 	min-width: 278px;
			// 	max-width: 320px;
			// }
		}
	}

	&.close {
		animation: closeMenu 0.2s ease-in-out forwards;

		.menu {
			visibility: hidden;
		}
	}

	.unstyled {
		// TODO
		position: absolute;
		background-color: $menuBackgroundColor;
		box-shadow: $menuBoxShadow;
		top: $menuTopPosition;
		padding: $menuUnstyledPadding;
		border-radius: $menuBorderRadius;

		&.alignToRight {
			right: $menuAlignToRightFromRight !important;
			left: auto !important;
			transform: unset !important;
			transform-origin: unset !important;

			&::after {
				left: auto !important;
				right: $menuAlignToRightFromRightAfter !important;
			}
		}

		&.alignToLeft {
			left: $menuAlignToLeftFromLeft !important;
			transform: unset !important;
			transform-origin: unset !important;

			&::after {
				left: $menuAlignToLeftFromLeftAfter !important;
			}
		}

		&.normalAlign {
		}

		&.fromTop {
			transform-origin: top;
		}
	}

	.menu {
		position: absolute;
		width: max-content;
		text-align: center;
		word-break: break-word;
		white-space: pre;
		overflow: auto;
		top: $menuTopPosition;
		box-shadow: $menuBoxShadow;
		background-color: $menuBackgroundColor;
		padding: $menuPadding;
		border-radius: $menuBorderRadius;
		line-height: $menuLineHeight;
		font-weight: $menuFontWeight;
		font-size: $menuFontSize;
		min-width: $menuMinWidth;
		max-width: $menuMaxWidth;
		max-height: $menuMaxHeight;

		&.alignToRight {
			right: $menuAlignToRightFromRight !important;
			left: auto !important;
			transform: unset !important;
			transform-origin: unset !important;

			&::after {
				left: auto !important;
				right: $menuAlignToRightFromRightAfter !important;
			}
		}

		&.alignToLeft {
			left: $menuAlignToLeftFromLeft !important;
			transform: unset !important;
			transform-origin: unset !important;

			&::after {
				left: $menuAlignToLeftFromLeftAfter !important;
			}
		}

		&.normalAlign {
		}

		&.fromTop {
			transform-origin: top;
		}
	}
}
