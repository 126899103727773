@import 'src/styles/theme';

.navBar {
	min-height: 50px;
	padding: 0px 23px 0px 20px;
	border-bottom: 1px solid #e7eaf0;
	background-color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	z-index: 5;
	width: -webkit-fill-available;

	.end {
		display: flex;
		gap: 0;

		.divider {
			font-size: 19px;
			border-left: $subTitleColor 1px solid;
			max-height: 25px;
			min-height: 25px;
			align-self: center;
		}
	}
}
