@import 'src/styles/theme';

.modalWrapper {
	padding: 10px;

	.sharedTargetModificationText {
		font-size: 14px;
		margin-bottom: 5px;
	}
}

.titleWrapper {
	.title {
		color: $titleColor;
		font-size: 24px;
		font-weight: bold;
	}

	.campaignStatus {
		display: flex;
		align-items: center;
		color: $titleColor;
		font-size: 16px;
	}
}
