.numberFilterInputField {
	font-size: 12px;

	div {
		width: 100%;

		input {
			font-size: 12px !important;
			font-weight: 500 !important;
			color: #101810;
		}
	}
}
