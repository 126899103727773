@import './chart-tooltip.default.scss';
@import '@monorepo/base/src/styles/text/overflow';


.tooltip {
	position: absolute;
	z-index: 4;
	background-color: $chartTooltipTooltipBackgroundColor;
	box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
	border-radius: 8px;
	padding: $chartTooltipTooltipPadding;
	min-width: $chartTooltipTooltipMinWidth;
	min-height: 81px;

	&.donutTooltip {
		max-height: 90px;
	}

	.date {
		font-size: $chartTooltipDateFontSize;
		font-weight: $chartTooltipDateWeight;
		color: $chartTooltipDateColor;
		border-bottom: $chartTooltipDateBorderBottom;
		padding: $chartTooltipDatePadding;
		margin: $chartTooltipDateMargin;
	}

	.content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: $chartTooltipContentMargin;

		.lineLabel {
			display: flex;
			align-items: center;
			margin: $chartTooltipLineLabelMargin;

			.point {
				width: $chartTooltipPointWidth;
				height: $chartTooltipPointHeight;
				margin: $chartTooltipPointMargin;
				border-radius: 50%;
			}

			.label {
				font-size: $chartTooltipLabelFontSize;
				@include text-overflow;
				max-width: 95px;
			}
		}

		.value {
			font-size: $chartTooltipValueFontSize;
			font-weight: $chartTooltipValueWeight;
		}
	}
}
