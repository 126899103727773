@import './button-group.default.scss';

.wrapper {

	&.disabled {
		cursor: not-allowed;
	}

	.title {
		color: $buttonGroupTitleColor;
		font-size: $buttonGroupTitleFontSize;
		margin: $buttonGroupTitleMargin;
	}

	.group {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: $buttonGroupFlexWrap;
		gap: $buttonGroupGap;
	}

	.error {
		color: $buttonGroupErrorColor;
		font-size: $buttonGroupErrorFontSize;
		line-height: $buttonGroupErrorLineHeight;
		opacity: $buttonGroupErrorOpacity;
		height: $buttonGroupErrorHeight;
		transition: all 0.2s ease-in-out;

		&.show {
			height: 100%;
			opacity: $buttonGroupErrorShowOpacity;
			margin-top: $buttonGroupErrorShowMargin;
			padding-left: $buttonGroupErrorShowPadding;
		}
	}
}
