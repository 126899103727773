/* width */
// ::-webkit-scrollbar {
// 	width: 8px;
// 	height: 7px;
// 	scroll-padding-right: 10px;
// }

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
// ::-webkit-scrollbar-thumb {
// 	// background: rgb(202, 202, 202);
// 	background: #EAECF0;
// 	border-radius: 8px;
// }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	// background: rgb(161, 161, 161);
	border: 4px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
	border-radius: 9999px;
	background-color: rgb(202, 202, 202);
}

::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}

::-webkit-scrollbar-thumb {
	border: 4px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
	border-radius: 9999px;
	background-color: #EAECF0;
}