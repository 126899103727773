@import '@monorepo/base/src/styles/keyframes/opacity-in-out';
@import './sidebar.default.scss';

.wrapper {
	padding: $controlledSidebarWrapperPadding;
	max-height: 100vh;
	overflow-y: auto;
	overflow-x: hidden;

	.toggler {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: $controlledSidebarTogglerHeight;
		padding: $controlledSidebarTogglerPadding;
	}

	.fullList {
		.itemWrapper {
			.itemTitle {
				margin: $controlledSidebarFullListItemTitleMargin;
				white-space: nowrap;
			}
		}
	}

	.list {
		margin: $controlledListMargin;

		.itemWrapper {
			width: $controlledListItemWrapperWidth;
			height: $controlledListItemWrapperHeight;
			line-height: $controlledListItemWrapperLineHeight;
			font-size: $controlledListItemWrapperFontSize;
			padding: $controlledListItemWrapperPadding;
			cursor: pointer;
			margin: $controlledListItemWrapperMargin;
			display: flex;
			align-items: center;
			border-radius: $controlledListItemWrapperBorderRadius;

			&:hover {
				background-color: $controlledListItemWrapperHoverBackgroundColor;
			}

			&.activeItem {
				background-color: $controlledListItemWrapperActiveItemBackgroundColor;
				border-radius: $controlledListItemWrapperActiveItemBorderRadius;

				&:hover {
					background-color: $controlledListItemWrapperActiveItemHoverBackgroundColor;
				}

				.itemLink {
					color: $controlledListItemWrapperActiveItemLinkColor;
				}
			}

			.itemLink {
				height: 100%;
				width: 100%;

				.item {
					display: flex;
					align-items: center;
					height: 100%;
					width: 100%;
				}
			}
		}
	}
}