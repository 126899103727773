$tagPadding: var(--tagPadding, 7px 10px 7px 7px); 
$tagBorderRadius: var(--tagBorderRadius, 16px); 
$tagGap: var(--tagGap, 5px); 
$tagFontWeight: var(--tagFontWeight, 500); 
$tagMinWidth: var(--tagMinWidth, 53px); 
$tagHeight: var(--tagHeight, 22px); 
$tagFontSize: var(--tagFontSize, 12px);

$tagSuccessColor: var(--tagSuccessColor, #027a48); 
$tagBackgroundColor: var(--tagBackgroundColor, #ecfdf3); 

$tagErrorColor: var(--tagErrorColor, #fb6340); 
$tagErrorBackgroundColor: var(--tagErrorBackgroundColor, #ffeeeb); 

$tagNewColor: var(--tagNewColor, #026aa2); 
$tagNewBackgroundColor: var(--tagNewBackgroundColor, #f0f9ff); 

$tagInfoColor: var(--tagInfoColor, #7c7c7c); 
$tagInfoBorder: var(--tagInfoBorder, 1px solid #7c7c7c); 

$tagWarningColor: var(--tagWarningColor, #ffc107); 
$tagWarningBorder: var(--tagWarningBorder, 1px solid #ffc107); 

$tagPendingColor: var(--tagPendingColor, #3538cd); 
$tagPendingBackgroundColor: var(--tagPendingBackgroundColor, #eef4ff); 

$tagPausedColor: var(--tagPausedColor, #344054); 
$tagPausedBackgroundColor: var(--tagPausedBackgroundColor, #f2f4f7); 

$tagDraftColor: var(--tagDraftColor, #344054); 
$tagDraftBackgroundColor: var(--tagDraftBackgroundColor, #f2f4f7);  

$tagDeletedColor: var(--tagDeletedColor, #B42318 ); 
$tagDeletedBackgroundColor: var(--tagDeletedBackgroundColor, #FEF3F2); 

$tagIconMaxWidth: var(--tagIconMaxWidth, 10px); 
$tagIconFontSize: var(--tagIconFontSize, 13px); 
$tagIconSuccessColor: var(--tagIconSuccessColor, #12b76a); 
$tagIconErrorColor: var(--tagIconErrorColor, #ff5029); 
$tagIconPadding: var(--tagIconPadding, 0px 0px 1px 0px); 
$tagIconNewColor: var(--tagIconNewColor, #0ba5ec); 
$tagIconPendingColor: var(--tagIconPendingColor, #1c1fd8); 