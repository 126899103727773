@import '@monorepo/base/src/styles/devices';
@import '@monorepo/base/src/styles/scrollbar';
@import './font';

$primaryColor: #6366f1;
$primaryColorLight: #e2e2ff80;
$lightBlueBg: #f0f9ff;
$primaryColorDarken: #4f46e5;
$secondaryColor: #6b6b6b;
$backgroundColor: #f8f8f8;
$titleColor: #6b6b6b;
$subTitleColor: #e7e7e7;
$hoveredTitleColor: #1d1d1d;
$red: #fb6340;
$dangerColor: rgb(220, 53, 69);
$dangerColorHover: rgb(187, 45, 59);

//form
$labelColor: #7c7c7c;
$borderElementsColor: #d0d5dd;
$maxWidthElements: 400px;
$errorColor: #fb6340;
$errorColorLight: #fa7557;
$successColor: #2dce89;
$successColorLight: #3bd994;
$whiteColor: #ffffff;

$disabledBackgroundColor: #acacac;
$disabledColor: #000000;

$actionLinerIconTextColor: white;
$actionLinerIconColor: white;

$gray600: #475467;

$hoverLinksBg: #bcbcbc28;
