@import './dater-theme.default.scss';

$circleHeight: 34px;
$borderDashedColor: $borderHoverCalendar;

// CSS Structure
// {
// 	rdrDateRangePickerWrapper: {
// 		rdrDefinedRangesWrapper: {
// 			rdrStaticRanges,
// 			rdrInputRanges
// 		},
// 		rdrCalendarWrapper, rdrDateRangeWrapper: {
// 			rdrDateDisplayWrapper,
// 			rdrMonthAndYearWrapper
// 		},
// 		rdrWeekDays: {
// 			rdrWeekDay
// 		},
// 		rdrInfiniteMonths, rdrMonthsVertical: {
// 			rdrMonth: {
// 				rdrMonthName,
// 				rdrDays: {
// 					rdrDay, rdrDayHovered, rdrDayPassive, rdrDayWeekend, rdrDayStartOfWeek, rdrDayEndOfWeek: {
// 						rdrInRange, rdrDayNumber, rdrEndRange, rdrStartEdge, rdrDayStartPreview, rdrDayEndPreview, rdrDayInPreview, rdrDayEndOfMonth, rdrSelected
// 					}
// 				}
// 			}
// 		}
// 	}
// }

.rdrDateRangePickerWrapper {

	.rdrDayStartPreview {
		height: $circleHeight;
		border-top-width: 1px;
		border-style: dashed;
		border-left-width: 1px;
		border-color: $borderDashedColor;
		border-bottom-width: 1px;
		border-top-left-radius: 50%;
		border-bottom-left-radius: 50%;
		left: 2px;
		bottom: 0px;
		z-index: -1;
		top: 0px;
	}

	.rdrDayInPreview {
		border-top-width: 1px;
		border-style: dashed;
		border-color: $borderDashedColor;
		bottom: 0px;
		top: 0px;
		z-index: -1;
		border-bottom-width: 1px;
	}

	.rdrDayEndPreview {
		height: $circleHeight;
		border-top-width: 1px;
		border-color: $borderDashedColor;
		border-style: dashed;
		border-right-width: 1px;
		border-bottom-width: 1px;
		border-top-right-radius: 50%;
		border-bottom-right-radius: 50%;
		bottom: 0px;
		top: 0px;
		z-index: -1;
		right: 2px;

	}

	.rdrDay {
		.rdrInRange,
		.rdrEndRange {
			&~.rdrDayStartPreview, &~.rdrDayInPreview, &~.rdrDayEndPreview {
				z-index: 3;
			}
		}
	}
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
	content: '';
	height: $circleHeight;
	border: 1px dashed currentColor;
	border-radius: 50%;
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	background: transparent;
}

.calendarWrapper {
	.rdrSelected {
		border-radius: 50% !important;
		height: $circleHeight;
		background-color: $startEndEdgeBackgroundColor !important;
		z-index: 2;

		&~.rdrDayNumber {
			z-index: 3;

			>span {
				color: white !important;
			}
		}
	}

	.rdrDayHovered {
		.rdrSelected {
			&~.rdrDayNumber:after {
				border: none !important;
			}
		}
	}
}

.rdrCalendarWrapper {
	color: #000000;
	font-size: 12px;
}

.rdrDateDisplayWrapper {
	background-color: transparent;
}

.rdrDateDisplay {
	margin: 0.833em;
}

.rdrDateDisplayItem {
	border-radius: 4px;
	background-color: rgb(255, 255, 255);
	border: 1px solid #d0d5dd;

	input {
		height: 2.5em;
		line-height: 2.5em;
		border: 0px;
		background: transparent;
		width: 100%;
		color: #101828;
	}
}

.rdrDateDisplayItemActive {
	// border-color: currentColor;
}

.rdrDateDisplayItemActive {
	input {
		color: #101828;
	}
}

.rdrMonthAndYearWrapper {
	align-items: center;
	height: 60px;
	padding-top: 10px;
}

.rdrMonthAndYearPickers {
	font-weight: 600;

	select {
		appearance: none;
		-webkit-appearance: none;
		border: 0;
		background: transparent;
		padding: 10px 30px 10px 10px;
		border-radius: 4px;
		outline: 0;
		color: #3e484f;
		background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
		background-position: right 8px center;
		cursor: pointer;
		text-align: center;

		&:hover {
			background-color: rgba(0, 0, 0, 0.07);
		}
	}
}

.rdrMonthPicker,
.rdrYearPicker {
	margin: 0 5px;
}

.rdrNextPrevButton {
	display: block;
	width: 24px;
	height: 24px;
	margin: 0 0.833em;
	padding: 0;
	border: 0;
	border-radius: 5px;
	background: transparent;

	&:hover {
		background: rgba(0, 0, 0, 0.07);
	}

	i {
		display: block;
		width: 0;
		height: 0;
		padding: 0;
		text-align: center;
		border-style: solid;
		margin: auto;
		transform: translate(-3px, 0px);
	}
}

.rdrPprevButton {
	i {
		border-width: 4px 6px 4px 4px;
		border-color: transparent rgb(52, 73, 94) transparent transparent;
		transform: translate(-3px, 0px);
	}
}

.rdrNextButton {
	i {
		margin: 0 0 0 7px;
		border-width: 4px 4px 4px 6px;
		border-color: transparent transparent transparent rgb(52, 73, 94);
		transform: translate(3px, 0px);
	}
}

.rdrWeekDays {
	padding: 0 0.833em;
}

.rdrMonth {
	padding: 0 0.833em 1.666em 0.833em;

	.rdrWeekDays {
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName {
	display: none;
}

.rdrWeekDay {
	font-weight: 400;
	line-height: 2.667em;
	color: rgb(132, 144, 149);
}

.rdrDay {
	background: transparent;
	user-select: none;
	border: 0;
	padding: 0;
	line-height: 3em;
	height: 3em;
	text-align: center;
	color: #1d2429;

	&:focus {
		outline: 0;
	}
}

.rdrDayNumber {
	outline: 0;
	font-weight: 300;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

// today
.rdrDayToday .rdrDayNumber span {
	font-weight: 500;

	// &:after {
	// 	content: '';
	// 	position: absolute;
	// 	bottom: 0px;
	// 	left: 50%;
	// 	transform: translate(-50%, 0);
	// 	width: 18px;
	// 	height: 2px;
	// 	border-radius: 2px;
	// 	background: $dayNumberBackground;
	// }
}

.rdrDayToday:not(.rdrDayPassive) {

	.rdrInRange,
	.rdrStartEdge,
	.rdrEndEdge,
	.rdrSelected {
		&~.rdrDayNumber span:after {
			background: #fff;
		}
	}
}

.rdrDay:not(.rdrDayPassive) {

	.rdrInRange,
	.rdrSelected {
		&~.rdrDayNumber {
			span {
				color: #344054;
				font-weight: 400;
			}
		}
	}
}

.rdrDay:not(.rdrDayPassive) {

	.rdrStartEdge,
	.rdrEndEdge {
		&~.rdrDayNumber {
			span {
				position: absolute;
				z-index: 2;
				color: white;
				font-weight: 400;
			}
		}
	}
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
	height: calc($circleHeight - 2px);
	background: $selectedRangeBackground;
	position: absolute;
	z-index: 0;
	top: 1px;
	left: 0;
	right: 0;
	bottom: 1px;
}

.rdrInRange {
	margin: 0px -6px;
}

.rdrSelected {
	border-radius: 50%;
	left: 2px;
	right: 2px;
}

.rdrStartEdge,
.rdrEndEdge {
	z-index: 1;
	background-color: $startEndEdgeBackgroundColor;
}

.rdrStartEdge+.rdrDayNumber,
.rdrEndEdge+.rdrDayNumber {
	span {
		position: absolute;
		z-index: 2;
		color: white;
	}
}

.rdrStartEdge {
	height: $circleHeight;
	position: absolute;
	top: 0;
	right: 1px;
	left: 1px;
	bottom: 0px;
	border-radius: 50%;

	&:not(.rdrEndEdge) {
		&:before {
			content: " ";
			width: 7px;
			height: 6px;
			position: absolute;
			border-left: 2px solid $selectedRangeBackground;
			border-bottom-left-radius: 18px;
			top: 1px;
			right: -3px;
			background-color: $selectedRangeBackground;
		}

		&:after {
			content: " ";
			width: 7px;
			height: 6px;
			position: absolute;
			top: 27px;
			right: -3px;
			background-color: $selectedRangeBackground;
			border-left: 2px solid $selectedRangeBackground;
			border-top-left-radius: 18px;
		}
	}
}

.rdrEndEdge {
	height: $circleHeight;
	position: absolute;
	right: 1px;
	left: 1px;
	top: 0;
	bottom: 0px;
	border-radius: 50%;

	&:not(.rdrStartEdge) {
		&:before {
			content: " ";
			width: 7px;
			height: 6px;
			position: absolute;
			border-right: 2px solid $selectedRangeBackground;
			border-bottom-right-radius: 18px;
			top: 1px;
			left: -3px;
			background-color: $selectedRangeBackground;
		}

		&:after {
			content: " ";
			width: 7px;
			height: 6px;
			position: absolute;
			top: 27px;
			left: -3px;
			background-color: $selectedRangeBackground;
			border-right: 2px solid $selectedRangeBackground;
			border-top-right-radius: 18px;
		}
	}

}

.rdrDayStartOfMonth,
.rdrDayStartOfWeek {

	.rdrInRange,
	.rdrEndEdge {
		left: 2px;
	}
}

.rdrDayEndOfMonth,
.rdrDayEndOfWeek {

	.rdrInRange,
	.rdrStartEdge {
		right: 2px;
	}
}

.rdrDayStartOfMonth,
.rdrDayStartOfWeek {

	.rdrDayInPreview,
	.rdrDayEndPreview {
		left: 2px;
	}
}

.rdrDayEndOfMonth,
.rdrDayEndOfWeek {

	.rdrDayInPreview,
	.rdrDayStartPreview {
		right: 2px;
	}
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
	background: rgba(255, 255, 255, 0.09);
	height: $circleHeight;
	position: absolute;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	pointer-events: none;
	border: 0px solid currentColor;
	z-index: 1;
}

.rdrDefinedRangesWrapper {
	font-size: 12px;
	width: 226px;
	border-right: solid 1px #eff2f7;
	background: #fff;

	.rdrStaticRangeSelected {
		color: currentColor;
		font-weight: 600;
	}
}

.rdrStaticRange {
	border: 0;
	cursor: pointer;
	display: block;
	outline: 0;
	// border-bottom: 1px solid #eff2f7;
	padding: 0;
	background: #fff;

	&:hover,
	&:focus {
		.rdrStaticRangeLabel {
			background: #eff2f7;
		}
	}
}

.rdrStaticRangeLabel {
	display: block;
	outline: 0;
	line-height: 18px;
	padding: 10px 20px;
	text-align: left;
	font-size: 13px;
}

.rdrInputRanges {
	padding: 10px 0;
}

.rdrInputRange {
	align-items: center;
	padding: 5px 20px;
}

.rdrInputRangeInput {
	width: 40px;
	height: 35px;
	line-height: 30px;
	border-radius: 4px;
	text-align: center;
	border: solid 1px rgb(222, 231, 235);
	margin-right: 10px;
	color: rgb(108, 118, 122);

	&:focus,
	&:hover {
		border-color: rgb(180, 191, 196);
		outline: 0;
		color: #333;
	}
}

.rdrInputRangeLabel {
	font-size: 13px;
}

.rdrDayPassive {
	pointer-events: none;

	.rdrDayNumber span {
		color: #d5dce0;
	}

	.rdrInRange,
	.rdrStartEdge,
	.rdrEndEdge,
	.rdrSelected,
	.rdrDayStartPreview,
	.rdrDayInPreview,
	.rdrDayEndPreview {
		display: none;
	}
}

.rdrDayDisabled {
	// background-color: rgb(248, 248, 248);

	.rdrDayNumber span {
		color: #aeb9bf;
	}

	.rdrInRange,
	.rdrStartEdge,
	.rdrEndEdge,
	.rdrSelected,
	.rdrDayStartPreview,
	.rdrDayInPreview,
	.rdrDayEndPreview {
		filter: grayscale(100%) opacity(60%);
	}
}

.rdrMonthName {
	text-align: left;
	font-weight: 600;
	color: #849095;
	padding: 0.833em;
}