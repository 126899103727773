.rdrCalendarWrapper {
	box-sizing: border-box;
	background: #ffffff;
	display: inline-flex;
	flex-direction: column;
	user-select: none;
}

.rdrDateDisplay {
	display: flex;
	justify-content: space-between;
	align-items: center;

	span {
		font-size: 16px;
		color: #667085;
		margin: 0 6px;
	}
}

.rdrDateDisplayItem {
	flex: 1 1;
	width: 0;
	text-align: center;
	color: inherit;
	& + & {
		margin-left: 0.833em;
	}
	input {
		text-align: inherit;
		&:disabled {
			cursor: default;
		}
	}
}

.rdrDateDisplayItemActive {
}

.rdrMonthAndYearWrapper {
	box-sizing: inherit;
	display: flex;
	justify-content: space-between;
}

.rdrMonthAndYearPickers {
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
	align-items: center;
}

.rdrMonthPicker {
}

.rdrYearPicker {
}

.rdrNextPrevButton {
	box-sizing: inherit;
	cursor: pointer;
	outline: none;
}

.rdrPprevButton {
}

.rdrNextButton {
}

.rdrMonths {
	display: flex;
}

.rdrMonthsVertical {
	flex-direction: column;
}

.rdrMonthsHorizontal > div > div > div {
	display: flex;
	flex-direction: row;
}

.rdrMonth {
	width: 27.667em;
}

.rdrWeekDays {
	display: flex;
	align-items: center;
	justify-content: center;
}

.rdrWeekDay {
	flex-basis: 13%;
	box-sizing: inherit;
	text-align: center;
}

.rdrDays {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	row-gap: 2px;
}

.rdrDateDisplayWrapper {
	order: 3;
	position: absolute;
	width: 340px;
	left: 11px;
	bottom: 2px;
}

.rdrMonthName {
}

.rdrInfiniteMonths {
	overflow: auto;
}
