@import 'src/styles/theme.scss';

.formWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 75px;

	.pagebar {
		margin-top: 20px;
	}

	.segmentation {
		display: flex;
		flex-direction: column;
		gap: 20px;

		.closeIcon {
			position: relative;
			width: 15px;
			top: -18px;
			margin-left: auto;
		}

		.limitLoaderSize {
			max-width: 350px;
			position: relative;
			top: -2px;
			left: 5px;
		}
	}

	.title {
		margin-bottom: -10px;
		margin-top: 1%;
		h2 {
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 1px;
		}

		sub {
			margin-top: 2px;
			margin-bottom: 2px;
		}
	}

	.inputStyle {
		width: 360px;
		cursor: not-allowed;
	}

	.addSegment {
		margin-top: 25px;
	}

	.dropdownWrapper {
		padding-top: 10px;
		max-width: 360px;

		.parentSelectWrapper {
			position: absolute;
			top: 52px;
		}
	}
}
