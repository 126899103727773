@import './avatar.default.scss';

.avatar {
	border-radius: $avatarBorderRadius;
	width: $avatarWidth;
	height: $avatarHeight;
}

.noPictureAvatar {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	// background-color: #f64b11;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}
