@import '@monorepo/base/src/styles/keyframes/opacity-in-out';
@import 'src/styles/theme';

.loadingPage {
	cursor: wait;
	pointer-events: none;
	opacity: 0.4;
}

.title {
	color: $titleColor;
	font-size: 24px;
	font-weight: bold;
	white-space: nowrap;
}

.createBtn {
	max-width: 100px;
}

.wrapper {
	position: relative;
	width: 100%;

	&.sticky {
		animation: opacityIn 0.2s ease-in;

		.bar {
			position: fixed;
			bottom: 0px;
			right: 0px;
			left: 0px;
		}
	}

	.bar {
		width: 100%;
		max-width: 100%;
		height: 75px;
		background-color: white;
		display: flex;
		align-items: center;
		box-shadow: 0 0 2rem 0 rgb(136 152 170 / 15%);

		.actions {
			position: absolute;
			right: 20px;
		}
	}
}
