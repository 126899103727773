@import 'src/styles/theme';

.title {
	color: $titleColor;
	font-size: 24px;
	font-weight: bold;
	white-space: nowrap;
}

.editBtn {
	max-width: 100px;
}
