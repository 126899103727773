@import 'src/styles/theme.scss';

.formWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 75px;

	.pagebar {
		margin-top: 20px;
	}

	.title {
		margin-bottom: -10px;
		margin-top: 1%;
		h2 {
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 1px;
		}

		sub {
			margin-top: 2px;
			margin-bottom: 2px;
		}
	}

	.deleteAllTargetCidsBtn {
		border: unset;
		position: relative;
		right: -229px;
		top: 32px;
		width: 112px;
		color: #6b6b6b;

		&:hover {
			background: transparent;
			background-color: rgb(202 202 202 / 30%);
		}
	}
	.row {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		gap: 3%;
		&.error {
			margin-bottom: 20px;
		}
	}

	.input {
		margin-bottom: 10px;
		margin-top: 10px;
		width: 30%;
	}

	.sourceCard {
		margin-top: 20px;
		margin-bottom: 20px;
		padding-bottom: 44px;
	}

	.targetCard {
		position: relative;
		margin-top: 20px;
		margin-bottom: 20px;
		padding-right: 16px;
		padding-bottom: 44px;
		gap: 10px;

		.groupNumber {
			margin-top: 16px;
			font-size: 14px;
		}

		.deleteTargetCidBtn {
			max-width: 30px;
			border: none;
			border-radius: 20px;

			margin-right: -10px;
			margin-top: 18px;

			&:hover {
				background-color: unset !important;
				color: unset !important;
			}
		}
	}

	.addTargetCidsBtn {
		width: fit-content;
		border: unset;
		color: #6b6b6b;
		margin-top: -13px;

		&:hover {
			background: transparent;
			background-color: rgb(202 202 202 / 30%);
		}
	}
}
