@import '../theme.scss';

:root {
	--gButtonBackgroundColor: #f8f8f8;
	--gButtonHoverBackgroundColor: #e2e2ff80;
	--gButtonHeight: 40px;
	--gButtonMaxWidth: 250px;
	--gButtonActiveBorder: 1px solid #6366f1;
	--gButtonBorderRadius: 3px;
	--gButtonMargin: 0px 10px 0px 0px;
	--gButtonTextMargin: 0px 0px 0px 5px;
	--gButtonIconSize: 20px;
	--gButtonBorderRadius: 3px;
	--gButtonActiveBackgroundColor: #e2e2ff80;

	--buttonGroupGap: 0px;
	--buttonGroupFlexWrap: nowrap;
}