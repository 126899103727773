.targetings {
	display: flex;
	flex-direction: column;

	.targetingList {
		display: flex;
		flex-direction: column;

		.targetingItem {

			display: flex;
			flex-direction: column;
			gap: 1rem;

			.inputsWrapper {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				gap: 1rem;
				max-width: 770px;
			}

			.inputWrapper {
				display: flex;
				flex-direction: column;
				gap: 5px;

				.inputWithHint {
					margin: 0;
				}

				.fromError {
					margin: 0;
					padding: 0;
				}
			}

		}
	}

	.wrapper {
		padding: 0.75rem 1rem 1.5rem 1rem !important;
		display: flex;
		flex-direction: column;
		gap: 16px;



		.targetingLinkWrapper {
			margin-top: 10px;

			.yidAndIcon {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
				position: relative;
				top: 7px;

				.arrowIcon {
					position: relative;
					top: 12px;
				}
			}

			.cidsWrapper {
				margin-top: 20px;

				.cidWrapper {
					display: flex;
					gap: 10px;
				}
			}
		}

		.publishersListDropdown {
			margin-top: 20px;
			max-width: 400px;
		}

		.parentSelectWrapper {
			position: absolute;
			top: 65px;
			z-index: 4;
		}
	}
}

.createButtonWrapper {
	width: 100%;
	display: flex;
	align-items: center;
	color: #6366f1;

	.addIconButton {
		padding: .3rem;
	}
}

.w100 {
	width: 100%;
	max-width: 100% !important;

	> div, input {
		width: 100%;
		max-width: 100% !important;
	}
}
