.cardSpacer {
	display: flex;
	flex-direction: column;
	gap: 25px;

	.dropdownWrapper {
		max-width: 400px;
	}

	.parentSelectWrapper {
		position: absolute;
		top: 65px;
	}
}
