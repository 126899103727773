$breadcrumbMenuArrowDownWrapperHeight: var(--breadcrumbMenuArrowDownWrapperHeight, 50px);
$breadcrumbMenuArrowDownHoverBackgroundColor: var(--breadcrumbMenuArrowDownHoverBackgroundColor, #bcbcbc28);
$breadcrumbMenuArrowDownFromTop: var(--breadcrumbMenuArrowDownFromTop, 22px);
$breadcrumbMenuArrowDownFontSize: var(--breadcrumbMenuArrowDownFontSize, 20px);
$breadcrumbMenuArrowDownColor: var(--breadcrumbMenuArrowDownColor, #000000);

$breadcrumbMenuLinkWrapperColor: var(--breadcrumbMenuLinkWrapperColor, #6b6b6b);
$breadcrumbMenuLinkWrapperHeight: var(--breadcrumbMenuLinkWrapperHeight, 50px);
$breadcrumbMenuLinkWrapperHoverBackgroundColor: var(--breadcrumbMenuLinkWrapperHoverBackgroundColor, #bcbcbc28);

$breadcrumbMenuLinkPadding: var(--breadcrumbMenuLinkPadding, 6px 5px 0px 5px);

$breadcrumbMenuLinkKeyFontSize: var(--breadcrumbMenuLinkKeyFontSize, 11px);

$breadcrumbMenuLinkValueFontSize: var(--breadcrumbMenuLinkValueFontSize, 14.5px);
$breadcrumbMenuLinkValueFontWeight: var(--breadcrumbMenuLinkValueFontWeight, 500);
$breadcrumbMenuLinkValuePadding: var(--breadcrumbMenuLinkValuePadding, 3px 0px 0px 0px);

$breadcrumbMenuNextFontSize: var(--breadcrumbMenuNextFontSize, 30px);
$breadcrumbMenuNextFontWeight: var(--breadcrumbMenuNextFontWeight, 100);
$breadcrumbMenuNextMargin: var(--breadcrumbMenuNextMargin, 0px 15px);
$breadcrumbMenuNextColor: var(--breadcrumbMenuNextColor, #dadce0);
$breadcrumbMenuNextLineHeight: var(--breadcrumbMenuNextLineHeight, 48px);