/* tooltip.scss */
.tooltip-container {
	position: absolute;
	z-index: 90;
	opacity: 0;
	border-radius: 10px;
	padding: 10px;
	background-color: black;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	min-width: 40px;
}

.tooltip-text {
	// position: absolute !important;
	color: #fff;
	text-align: center;
	white-space: normal;
	transition: opacity 0.3s;
	pointer-events: auto;
	word-wrap: break-word;
}

.tooltip-container.visible {
	opacity: 1;
}

.tooltip-container:hover {
	opacity: 1;
}

// .tooltip-text-wrapper::after {
// 	content: '';
// 	position: absolute;
// 	left: 50%;
// 	transform: translate(-50%, 100%);
// 	border-width: 10px;
// 	border-style: solid;
// 	border-color: black transparent transparent transparent; /* Arrow color */
// 	z-index: 1999;
// 	display: block;
// 	bottom: 0; /* Position the arrow at the bottom */
// }

// .tooltip-text-wrapper::before {
// 	content: '';
// 	position: absolute;
// 	left: 50%;
// 	transform: translate(-50%, -150%);
// 	border-width: 10px;
// 	border-style: solid;
// 	border-color: transparent transparent black transparent; /* Arrow color */
// 	z-index: 1999;
// 	display: block;
// }
.tooltip-arrow-top {
	content: '';
	position: absolute;
	left: 50%;
	transform: translate(-50%, 100%);
	border-width: 10px;
	border-style: solid;
	border-color: black transparent transparent transparent; /* Arrow color */
	z-index: 1999;
	display: block;
	bottom: 0; /* Position the arrow at the bottom */
}

.tooltip-arrow-bottom {
	content: '';
	position: absolute;
	left: 50%;
	transform: translate(-50%, -150%);
	border-width: 10px;
	border-style: solid;
	border-color: transparent transparent black transparent; /* Arrow color */
	z-index: 1999;
	display: block;
}

.tooltip-text-wrapper {
	overflow: auto;
	max-width: 300px;
	max-height: 300px;
	text-align: center;
}
