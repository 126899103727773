@import './filter-enum-menu.default.scss';

.wrapper {
	// overflow: hidden;
	padding: 12px;

	.closeButton {
		float: right;
	}

	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: $filterEnumMenuTitleColor;
		text-align: left;
		font-size: 16px;
	}

	.subTitle {
		padding-top: 5px;
		text-align: left;
		font-weight: 500;
		font-size: 12px;
		color: #344054;
	}

	.actionsDiv {
		padding-top: 20px;
		text-align: left;
		font-weight: 500;
		font-size: 12px;
		color: #344054;
	}

	.actions {
		display: flex;
		margin-top: 10px;
		align-items: center;
		justify-content: flex-end;
		gap: 5px;

		.action {
			width: 33%;
			justify-content: flex-end;
			.cancelButton {
				padding: 0;
				border: none;
				background: none;
				color: #5552ee;
			}

			.cancelButton:hover {
				background-color: $hoverGrayColor;
			}
		}
	}

	.content {
		padding-top: 10px;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		gap: 10px;

		.dropdown {
			margin: 0;
		}
	}
}

.limitEnumSize {
	overflow-y: auto;
	max-height: 165px;
}
