@import './table-link.default.scss';

.wrapper {
	display: flex;
	align-items: center;
	.link {
		color: $tableLinkColor;
		font-weight: $tableLinkFontWeight;
	}

	.editLink {
		position: relative;
		color: $tableEditLinkColor;
		margin: $tableLinkEditLinkMargin;
		top: $tableLinkEditLinkFromTop;
	}
}
