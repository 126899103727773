@import './chart.default.scss';

.lineWrapper {
	position: relative;
	margin: auto;
	height: $chartLineWrapperHeight;
	width: 100%;
	padding: $chartLineWrapperPadding;
}

.dates {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: $chartDatesPadding;

	&.center {
		justify-content: center;
	}

	> span {
		font-size: $chartDatesFontSize;
	}
}

