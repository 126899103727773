@import 'src/styles/theme';
@import '@monorepo/base/src/styles/keyframes/opacity-in-out';

.wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem;
	padding-bottom: 100px;

	.dropdownWrapper {
		max-width: 400px;
	}

	.parentSelectWrapper {
		position: absolute;
		top: 65px;
	}
}

.dropdownSelectWrapper {
	max-width: 400px;
}

.wrapper > .div {
	margin-bottom: 100px;
}

.testGroupWrapper {
	position: relative;
	opacity: 1;
	transition: opacity 200ms linear;
	padding-bottom: 16px !important;

	&.normalGroup {
		margin-top: 25px;
		border-top: 1px solid #e5e7eb;
	}

	&.removeAnimation {
		animation: opacityOut 0.2s ease-in-out;
	}

	.testTitleWrapper {
		display: flex;
		align-items: center;
		gap: 12px;
		padding-top: 15px;

		.title {
			display: flex;
			align-items: center;
			font-weight: 500;
			color: $titleColor;

			.titleIcon {
				margin-right: 8px;
			}
		}
		.closeBtn {
			max-width: 30px;
			border: none;
			border-radius: 20px;
			padding-right: 8px;
			margin-left: auto;
		}
	}

	.actionsWrapper {
		margin-top: 25px;
		display: flex;
		gap: 10px;

		.actionBtn {
			max-width: 50px;
		}
	}
}
