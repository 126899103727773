@import './action-liner.default.scss';
@import '@monorepo/base/src/styles/keyframes/slide-in-up';

.selectedLinerButton,
.indexLinerButton {
	height: $actionLinerHeight;
	padding: $actionLinerNestedIndexLinerButtonPadding;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		text-decoration: none;
	}
}

.selectedLinerButton {
	&:hover {
		background-color: $actionLinerSelectedLinerButtonHoverBackgroundColor;
	}
}

.indexLinerButton {
	padding: $actionLinerIndexLinerButtonPadding;
	border-radius: $actionLinerIndexLinerButtonBorderRadius;
	display: flex;
	flex-direction: column;

	&:hover {
		background-color: $actionLinerIndexLinerButtonHoverBackgroundColor;
	}
}

.actionLiner {
	min-height: $actionLinerHeight;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: $actionLinerActionLinerMargin;

	.selectedLiner {
		width: 100%;
		height: $actionLinerHeight;
		background-color: $actionLinerSelectedLinerBackgroundColor;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $actionLinerSelectedLinerPadding;
		border-radius: $actionLinerSelectedLinerBorderRadius;
		color: $actionLinerSelectedLinerColor;

		&.animation {
			animation: slideInUp 0.3s cubic-bezier(0.33, 1, 0.68, 1) forwards;
		}

		.selectedActions {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: $actionLinerSelectedActionsGap;

			.amountTitle {
				font-size: $actionLinerAmountTitleFontSize;
			}
		}
	}

	.indexLiner {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		padding: $actionLinerIndexLinerPadding;

		&.noAnimation {
			animation: none;
		}

		&.animation {
			animation: slideInUp 0.15s cubic-bezier(0.33, 1, 0.68, 1);
		}

		.dynamicIndexActions {
			width: 100%;
			height: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			gap: $actionLinerDynamicIndexActionsGap;
		}

		.defaultIndexActions {
			height: $actionLinerHeight;
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}
}
