@import '../../theme.scss';

:root {
	--controlledDaterLabelFontWeight: 400;
	--controlledDaterBtnBorderColor: #6366f1;
	--controlledDaterBtnHoverColor: #e7e7e7;
	--controlledDaterLabelColor: #6b6b6b;
	--controlledDaterBtnColor: #6b6b6b;
	--controlledDaterIconColor: #6b6b6b;
	--controlledDaterCancelBtnHoverBackgroundColor: #e7e7e7;
}