:root {
	--inputWrapperMaxWidth: 400px;
	--inputWrapperMargin: 25px 0px 0px 0px;
	--inputHeight: 40px;
	--inputColor: #383838;
	--inputFontWeight: normal;
	--inputLineHeight: normal;
	--inputPlaceholderFocusOpacity: 0;
	--inputPlaceholderFocusLabelOpacity: 1;
	--inputFocusBorder: 1px solid #d0d5dd;
	--inputFocusBoxShadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}