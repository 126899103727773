@import 'src/styles/theme';

.preferenceFlowWrapper {
	width: 100%;
	padding-bottom: 25px;
}

.internalCreation {
	margin: 25px 1px 25px;
	width: 100%;
}

.internalInputs {
	width: 100%;
	max-width: 500px;
}

.title {
	display: flex;
	align-items: center;
	font-weight: 500;
	color: $titleColor;
	white-space: nowrap;

	.titleIcon {
		margin-right: 8px;
	}
}
