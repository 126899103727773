.contentWrapper {
	display: flex;
	flex-direction: column;
	gap: 15px;
	margin-bottom: 50px;

	.operations {
		display: flex;
		flex-direction: column;
		gap: 35px;
		position: relative;
		top: -10px;
	}
}

.dropdown {
	width: 100%;
	height: 80px;
	max-width: 400px;
	margin: 10px 0px 0px 0px;
}

.dropdownInput {
	height: 49px !important;
	border-radius: 6px !important;
}

.listItem {
	color: #474747;
}
