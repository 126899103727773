@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.spinner {
    transform-origin: center;
    animation: rotate 1s ease-in-out infinite;
}