@import './table-skeleton.default.scss';
@import './../table.default.scss';

.rectangle {
	width: 60%;
	height: $tableSkeletonRectangleHeight;
	border-radius: $tableSkeletonRectangleBorderRadius;
	background-color: $tableSkeletonRectangleBackgroundColor;
}

.row {
	// display: flex;
	// width: 100%;
	.cell {
		border-right: $tableTableTdBorderRight;
		border-bottom: $tableTableTdBorderBottom;
		padding: $tablePadding;
		color: $tableBodyTextColor;
		font-weight: $tableBodyTextFontWeight;
		font-size: $tableFontSize;
	}
}