@import './bottom-bar.default.scss';

.wrapper {
	position: $bottomBarPosition;
	width: 100%;
	left: $bottomBarFromLeft;
	right: $bottomBarFromRight;
	bottom: $bottomBarFromBottom;
	z-index: 3;

	.bar {
		width: 100%;
		max-width: 100%;
		display: flex;
		align-items: center;
		display: flex;
		justify-content: flex-end;
		height: $bottomBarHeight;
		background-color: $bottomBarBackgroundColor;
		box-shadow: $bottomBarBoxShadow;
		padding: $bottomBarPadding;
		gap: $bottomBarGap;
		border-bottom: $bottomBarBorderBottom;
		border-right: $bottomBarBorderRight;
		border-left: $bottomBarBorderLeft;
		border-top: $bottomBarBorderTop;
	}

	.actions {
		display: flex;
		gap: 16px;
	}
}