@import './quick-actions-menu.default';

.wrapper {
	height: 100%;
	// display: flex;
	// justify-content: center;
	// align-items: center;

	.quickActionsTrigger {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.actionsMenu {
		width: auto;
		max-width: 120px;
		background-color: white;
		box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
		padding: 0px;
		border-radius: 6px;
	}
}

.quickActionButton {
	height: 35px;
	width: 100%;
	padding-left: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;

	&:hover {
		background-color: $quickActionButtonHoverBackgroundColor;
		text-decoration: none;
	}

	&:first-child,
	&:last-child {
		&:hover {
			border-radius: inherit;

			>button {
				border-radius: inherit;

			}
		}
	}
}