@import './sidebar.default.scss';

.button {
	display: flex !important;
	padding: 0.5rem;
	align-items: flex-start !important;
	flex-direction: column;
	border-radius: 0.2rem;
	cursor: pointer;

	&:hover {
		background-color: $controlledListItemWrapperActiveItemBackgroundColor;
	}

	>div {
		transition: width 0.3s ease;
		width: 100%;
		min-width: 18px;
		background-color: $sidebarBurger;
		height: 2px;
	}
}