$actionModalContentIconWrapperPadding: var(--actionModalContentIconWrapperPadding, 0px 25px); 

$actionModalContentIconWidth: var(--actionModalContentIconWidth, 40px); 
$actionModalContentIconHeight: var(--actionModalContentIconHeight, 40px); 

$actionModalContentIconWarningBackgroundColor: var(--actionModalContentIconWarningBackgroundColor, #fee2e2); 
$actionModalContentIconWarningColor: var(--actionModalContentIconWarningColor, rgb(220, 53, 69)); 

$actionModalContentIconErrorBackgroundColor: var(--actionModalContentIconErrorBackgroundColor, #fee2e2); 
$actionModalContentIconErrorColor: var(--actionModalContentIconErrorColor, rgb(220, 53, 69)); 

$actionModalContentIconInfoBackgroundColor: var(--actionModalContentIconInfoBackgroundColor, #169e73); 
$actionModalContentIconInfoColor: var(--actionModalContentIconInfoColor, #d1fae5); 

$actionModalActionsBorderRadius: var(--actionModalActionsBorderRadius, 0px 0px 10px 10px); 
$actionModalActionsPadding: var(--actionModalActionsPadding, 15px); 
$actionModalActionsBackgroundColor: var(--actionModalActionsBackgroundColor, #f4f2f2); 
$actionModalActionsGap: var(--actionModalActionsGap, 15px); 
$actionModalActionsCancelBtnBorder: var(--actionModalActionsCancelBtnBorder, none); 
$actionModalActionsCancelBtnColor: var(--actionModalActionsCancelBtnColor, black); 
$actionModalActionsCancelBtnWidth: var(--actionModalActionsCancelBtnWidth, 100px); 
$actionModalActionsCancelBtnColorHover: var(--actionModalActionsCancelBtnColorHover, 100px); 

$actionModalActionsActionBtnWidth: var(--actionModalActionsActionBtnWidth, 150px); 

$actionModalActionsWarningBackgroundColor: var(--actionModalActionsWarningBackgroundColor, rgb(220, 53, 69)); 
$actionModalActionsWarningBorder: var(--actionModalActionsWarningBorder, none); 
$actionModalActionsWarningHoverBackgroundColor: var(--actionModalActionsWarningHoverBackgroundColor, rgb(187, 45, 59)); 

$actionModalActionsErrorBackgroundColor: var(--actionModalActionsErrorBackgroundColor, rgb(220, 53, 69)); 
$actionModalActionsErrorBorder: var(--actionModalActionsErrorBorder, none); 
$actionModalActionsErrorHoverBackgroundColor: var(--actionModalActionsErrorHoverBackgroundColor, rgb(187, 45, 59)); 
