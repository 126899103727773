$dropdownWrapperMaxWidth: var(--dropdownWrapperMaxWidth, 400px);
$dropdownWrapperMaxHeight: var(--dropdownWrapperMaxHeight, 44px);
$dropdownWrapperBackgroundColor: var(--dropdownWrapperBackgroundColor, white);
$dropdownWrapperBorderRadius: var(--dropdownWrapperBorderRadius, 6px);
$dropdownWrapperBorder: var(--dropdownWrapperBorder, 1px solid #d0d5dd);
$dropdownWrapperFontSize: var(--dropdownWrapperFontSize, 14px);
$dropdownWrapperColor: var(--dropdownWrapperColor, #344054);
$dropdownWrapperMargin: var(--dropdownWrapperMargin, 0px);

$dropdownArrowIcon: var(--dropdownArrowIcon, #d0d5dd);

$dropdownErrorBorder: var(--dropdownErrorBorder, 1px solid #fb6340);

$dropdownActiveBorder: var(--dropdownActiveBorder, 1px solid #d6bbfb);
$dropdownActiveBoxShadow: var(--dropdownActiveBoxShadow, 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #f4ebff);

$dropdownActiveLabelOpacity: var(--dropdownActiveLabelOpacity, 0);

$dropdownWrapperDisabledBackgroundColor: var(--dropdownWrapperDisabledBackgroundColor, #fff);
$dropdownWrapperDisabledBorder: var(--dropdownWrapperDisabledBorder, 1px solid #d0d5dd);

$dropdownDisabledLabelColor: var(--dropdownDisabledLabelColor, #d0d5dd);
$dropdownDisabledBackgroundColor: var(--dropdownDisabledBackgroundColor, white);
$dropdownDisabledOpacity: var(--dropdownDisabledOpacity, 1);

$dropdownLabelFromTop: var(--dropdownLabelFromTop, -12px);
$dropdrownLabelFromLeft: var(--dropdrownLabelFromLeft, 10px);
$dropdownLabelFontSize: var(--dropdownLabelFontSize, 12px);
$dropdownLabelBackgroundColor: var(--dropdownLabelBackgroundColor, white);
$dropdownLabelPadding: var(--dropdownLabelPadding, 0 6px);
$dropdownLabelColor: var(--dropdownLabelColor, #7c7c7c);
$dropdownLabelOpacity: var(--dropdownLabelOpacity, 0);
$dropdownLabelZIndex: var(--dropdownLabelZIndex, 0);

$dropdownSearchbarMargin: var(--dropdownSearchbarMargin, 5px 0px 0px 0px);
$dropdownSearchbarPadding: var(--dropdownSearchbarPadding, 12px 8px 8px 8px);

$dropdownPadding: var(--dropdownPadding, 10px 10px 10px 12px);
$dropdownFocusBorder: var(--dropdownFocusBorder, 1px solid #d6bbfb);
$dropdownFocusBoxShadow: var(--dropdownFocusBoxShadow, 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #f4ebff);

$dropdownOptionsBackgroundColor: var(--dropdownOptionsBackgroundColor, white);
$dropdownOptionsFromLeft: var(--dropdownOptionsFromLeft, 0px);
$dropdownOptionsFromTop: var(--dropdownOptionsFromTop, 50px);
$dropdownOptionsMargin: var(--dropdownOptionsMargin, 1px 0px 0px 0px);
$dropdownOptionsBorder: var(--dropdownOptionsBorder, 1px solid #d0d5dd);
$dropdownOptionsBorderRadius: var(--dropdownOptionsBorderRadius, 8px);
$dropdownOptionsMaxHeight: var(--dropdownOptionsMaxHeight, 300px);
$dropdownOptionsPadding: var(--dropdownOptionsPadding, 0);
$dropdownOptionsOpacity: var(--dropdownOptionsOpacity, 0);

$dropdownOptionPadding: var(--dropdownOptionPadding, 8px 8px 8px 16px);
$dropdownOptionMargin: var(--dropdownOptionMargin, 0px 0px 3px 0px);
$dropdownOptionHoverBackgroundColor: var(--dropdownOptionHoverBackgroundColor, #f2f2f2);
$dropdownOptionActiveBackgroundColor: var(--dropdownOptionActiveBackgroundColor, #e2e2e2);
$dropdownOptionHighlightBackgroundColor: var(--dropdownOptionHighlightBackgroundColor, #f9fafb);

$dropdownSeperatorLineMargin: var(--dropdownSeperatorLineMargin, 10px 0px);
$dropdownExtraHeight: var(--dropdownExtraHeight, 350px);
$dropdownDropdownBarDisabledColor: var(--dropdownDropdownBarDisabledColor, #d0d5dd);

$dropdownOptionActiveDisabledColor: var(--dropdownOptionActiveDisabledColor, #d0d5dd);
$dropdownOptionActiveArrowDisabledColor: var(--dropdownOptionActiveArrowDisabledColor, #d0d5dd);