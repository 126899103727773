@import '../theme.scss';

:root {
	--textButtonFontWeight: normal;
	--textButtonLineHeight: normal;
	--primaryTextButtonColor: #6366f1;
	--secondaryTextButtonColor: #6b6b6b;
	--secondayTextHoverBackgroundColor: transaprent;

	--primaryButtonBorderRadius: 4px;
	--primaryButtonFontWeight: normal;
	--primaryButtonColor: #ffffff;
	--primaryButtonPadding: 6px 10px;
	--primaryButtonHoverBackgroundColor: #4f46e5;

	--secondaryButtonBorderRadius: 4px;
	--secondaryButtonBackgroundColor: transparent;
	--secondaryButtonHoverBackgroundColor: #9ca3af;
	--secondaryButtonHoverColor: #ffffff;
	--secondaryButtonColor: #9ca3af;
	--secondaryButtonFontWeight: normal;
	--secondaryButtonBorder: 1px solid #9ca3af;
	--secondaryButtonPadding: 6px 10px;
}