@import './bar-loader.default.scss';

.loadingLine {
	position: relative;
	z-index: 2;
	top: $loadingLineFromTop;
	left: $loadingLineFromLeft;
	bottom: $loadingLineFromBottom;
	right: $loadingLineFromRight;
	width: 100%;
	height: $loadingLineHeight;
	transform-origin: 100% 0%;
	animation: loading-bar $loadingLineDuration cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;

	.loadingLineInner {
		position: absolute;
		width: 100%;
		transform-origin: 0% 0%;
		top: $loadingLineInnerFromTop;
		left: $loadingLineInnerFromLeft;
		bottom: $loadingLineInnerFromBottom;
		right: $loadingLineInnerFromRight;
		height: $loadingLineInnerHeight;
		background-color: $loadingLineInnerBackgroundColor;

		&.loadingLineInnerTransparent {
			opacity: $loadingLineInnerTransparentOpacity;
			animation: loading-bar-inner-1 $loadingLineDuration cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
		}

		&.loadingLineInnerColor {
			opacity: $loadingLineInnerColorOpacity;
			animation: loading-bar-inner-2 $loadingLineDuration cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
		}
	}
}

@keyframes loading-bar {
	0% {
		transform: scaleX(1);
	}

	50% {
		transform: scaleX(1);
	}

	100% {
		transform: scaleX(0);
	}
}

@keyframes loading-bar-inner-1 {
	0% {
		transform: scaleX(0);
	}

	25% {
		transform: scaleX(1);
	}

	100% {
		transform: scaleX(1);
	}
}

@keyframes loading-bar-inner-2 {
	0% {
		transform: scaleX(0);
	}

	25% {
		transform: scaleX(0);
	}

	50% {
		transform: scaleX(1);
	}

	100% {
		transform: scaleX(1);
	}
}