@import './nav.default.scss';

.wrapper {
	display: flex;

	.page {
		max-width: $navPageMaxWidth;
		width: 100%;
	}

	.navInPage {
		padding: $navInPagePadding;
	}
}

.nav {
	width: $navWidth;
	min-width: $navMinWidth;
	max-width: $navMaxWidth;
	overflow-y: scroll;
	max-height: calc(100% - 72px);
}

.navWrapper {
	position: relative;
	width: $navRelativeWrapper;

	.nav {
		position: fixed;
		padding: $navRelativeWrapperNavPadding;
		margin: $navRelativeWrapperNavMargin;
	}
}

.navGroup {
	&:not(&.unstyled) {

		padding: $navGroupPadding;
		border-top: $navGroupBorderTop;

		.navGroupToggler {
			border-radius: $navGroupTogglerBorderRadius;
			margin: $navGroupTogglerMargin;

			&:hover {
				background-color: $navGroupTogglerHoverBackgroundColor;
			}
		}

		.navGroupTitle {
			width: 100%;
			font-size: $navGroupTitleFontSize;
			font-weight: $navGroupTitleFontWeight;
			padding: $navGroupTitlePadding;
			color: $navGroupTitleColor;
		}

		.navItem {
			.navLink {
				padding: 5px 5px 5px 30px !important;
			}
		}
	}

}

.navItem {
	&:not(.unstyled) {
		cursor: pointer;
		font-size: $navItemFontSize;
		font-weight: $navItemFontWeight;
		margin: $navItemMargin;
		border-radius: $navItemBorderRadius;
		width: 100%;

		&:hover {
			background-color: $navItemHoverBackgroundColor;
		}

		&:first-child {
			margin-top: 5px;
		}

		&.activeRoute {
			.navLink {
				border-radius: $navItemBorderRadius;
				background-color: $navItemActiveRouteBackgroundColor;
				color: $navItemActiveRoutedColor;
			}
		}

		.navLink {
			padding: $navItemLinkPadding;
			color: $navItemColor;
			display: flex;
			align-items: center;
			gap: 4px;
			height: 100%;
			width: 100%;
		}

		.disabledItem {
			cursor: not-allowed;
		}
	}
}

.navList {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	text-align: left;
	color: $navListColor;

	.item {
		border-radius: $navListItemBorderRadius;
		margin: $navListItemMargin;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		line-height: $navListItemLineHeight;

		&:first-child {
			margin-top: 0px;
		}

		.children {
			font-size: $navListItemChildrenFontSize;
			padding: $navListItemChildrenPadding;
		}
	}
}

// TODO - remove
.disabledTooltip {
	left: 100px !important;
	bottom: 30px !important;
}