$siderSliderGreyScreenMaxWidth: var(--siderSliderGreyScreenMaxWidth, #2b2431a3);
$siderSliderWrapperMaxWidth: var(--siderSliderWrapperMaxWidth, 360px);
$siderSliderWrapperWidth: var(--siderSliderWrapperWidth, 500px);
$siderSliderWrapperFromTop: var(--siderSliderWrapperFromTop, 0);
$siderSliderWrapperBackgroundColor: var(--siderSliderWrapperBackgroundColor, white);
$siderSliderWrapperBorder: var(--siderSliderWrapperBorder, #e5e7eb 1px solid);
$siderSliderCloseIconWidth: var(--siderSliderCloseIconWidth, 40px);
$siderSliderCloseIconColor: var(--siderSliderCloseIconColor, #6b6b6b);
$siderSliderCloseIconFontSize: var(--siderSliderCloseIconFontSize, 20px);
$siderSliderCloseIconPadding: var(--siderSliderCloseIconPadding, 10px 10px 10px 10px);
$siderSliderCloseIconMargin: var(--siderSliderCloseIconMargin, 0 0 0 auto);