.domainOverviewPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-top: 0;
}

.pretatgetingAndHistoryWrapper {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    gap: 2rem;
    width: 100%;
    max-width: 100%;
}

.sectionWrapper {
    max-width: 100%;

    .tableAndTitle {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}