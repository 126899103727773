@import './tag.default.scss';

.tag {
	padding: $tagPadding;
	border-radius: $tagBorderRadius;
	display: flex;
	align-items: center;
	// gap: $tagGap;
	font-weight: $tagFontWeight;
	width: fit-content;
	// min-width: $tagMinWidth;
	height: $tagHeight;
	font-size: $tagFontSize;
	white-space: nowrap;
}

.success {
	color: $tagSuccessColor;
	background-color: $tagBackgroundColor;
}

.error {
	color: $tagErrorColor;
	background-color: $tagErrorBackgroundColor;
}

.new {
	color: $tagNewColor;
	background-color: $tagNewBackgroundColor;
}

.info {
	color: $tagInfoColor;
	border: $tagInfoBorder;
}

.warning {
	color: $tagWarningColor;
	border: $tagWarningBorder;
}

.pending {
	color: $tagPendingColor;
	background-color: $tagPendingBackgroundColor;
}

.paused {
	color: $tagPausedColor;
	background-color: $tagPausedBackgroundColor;
	gap: 5px;
}

.draft {
	color: $tagDraftColor;
	background-color: $tagDraftBackgroundColor;
	gap: 5px;
}

.deleted {
	color: $tagDeletedColor;
	background-color: $tagDeletedBackgroundColor;
	gap: 6px;
}

.icon {
	max-width: $tagIconMaxWidth;
	font-size: $tagIconFontSize;
	margin-right: 6px;

	&.success {
		color: $tagIconSuccessColor;
	}

	&.error {
		color: $tagIconErrorColor;
		padding: $tagIconPadding;
	}

	&.new {
		color: $tagIconNewColor;
	}

	&.pending {
		color: $tagIconPendingColor;
	}
}

.wrapper {
	display: flex;
	justify-content: baseline;
}
