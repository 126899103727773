@import './accordion.default.scss';

.wrapper {
	.toggler {
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;

		.title {
			margin: 0;
			display: flex;
			align-items: center;

			.titleIcon {
				margin-right: 8px;
			}
		}

		.icon {
			transition: 0.2s ease-in-out;
			color: $accordionTitleColor;

			&.open {
				transform: rotateZ(180deg);
			}
		}
	}

	.content {
		overflow: hidden;
		max-height: 0px;

		&.open {
			animation: openAcc 0.5s ease-in-out forwards;
		}

		&.close {
			visibility: hidden;
			animation: closeAcc 0.2s ease-in-out forwards;
		}
	}
}

@keyframes openAcc {
	0% {
		max-height: 0px;
	}

	100% {
		overflow: visible;
		max-height: 1000px;
	}
}

@keyframes closeAcc {
	0% {
		max-height: 1000px;
		visibility: visible;
		opacity: 1;
	}

	100% {
		max-height: 0px;
		opacity: 0;
		visibility: hidden;
	}
}
