@import 'src/styles/theme';

.tooltip {
	padding-left: 8px;
	text-align: left;
	color: $titleColor;
	font-size: 13px;
	.title {
		display: flex;
		justify-content: space-between;
		gap: 10px;
		margin-bottom: 10px;
		.link {
			font-size: 13px;
			color: #6366f1;
		}
	}
}
