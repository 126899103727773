$controlledDaterLabelFontSize: var(--controlledDaterLabelFontSize, 14px);
$controlledDaterLabelFontWeight: var(--controlledDaterLabelFontWeight, 500);
$controlledDaterLabelColor: var(--controlledDaterLabelColor, #344054);
$controlledDaterBtnMargin: var(--controlledDaterBtnMargin, 0 0 0 7px);
$controlledDaterBtnBorderColor: var(--controlledDaterBtnBorderColor, #d0d5dd);
$controlledDaterBtnColor: var(--controlledDaterBtnColor, #344054);
$controlledDaterBtnHoverColor: var(--controlledDaterBtnHoverColor, #f3f4f6);
$controlledDaterActionsBorderTop: var(--controlledDaterActionsBorderTop, solid 1px #eff2f7);
$controlledDaterActionsPadding: var(--controlledDaterActionsPadding, 10px 10px 0 0);
$controlledDaterActionsFontSize: var(--controlledDaterActionsFontSize, 14px);
$controlledDaterIconColor: var(--controlledDaterIconColor, #344054);
$controlledDaterCancelBtnHoverBackgroundColor: var(--controlledDaterCancelBtnHoverBackgroundColor, #f3f4f6);
$controlledDaterCancelBtnHoverBorderRadius: var(--controlledDaterCancelBtnHoverBorderRadius, 4px);