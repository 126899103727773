$selectionListWrapperMinHeight: var(--selectionListWrapperMinHeight, 40px);
$selectionListBorder: var(--selectionListBorder, 1px solid #d0d5dd);
// $selectionListMargin: var(--selectionListMargin, 40px 0px 30px 0px);
$selectionListBorderRadius: var(--selectionListBorderRadius, 6px 6px 0 0);

$selectionListClosedGap: var(--selectionListClosedGap, 3px);
$selectionListClosedPadding: var(--selectionListClosedPadding, 5px 30px 5px 5px);
$selectionListClosedBorderRadius: var(--selectionListClosedBorderRadius, 6px);
$selectionListClosedCloseIconFontSize: var(--selectionListClosedCloseIconFontSize, 13px);

$selectionListTitleFontSize: var(--selectionListTitleFontSize, 14px);
$selectionListTitleColor: var(--selectionListTitleColor, #6b6b6b);
$selectionListTitleEmptyFromTop: var(--selectionListTitleEmptyFromTop, 10px);
$selectionListTitleEmptyFromLeft: var(--selectionListTitleEmptyFromLeft, 15px);

$selectionListTitleNotEmptyFromTop: var(--selectionListTitleNotEmptyFromTop, -25px);
$selectionListTitleNotEmptyFromLeft: var(--selectionListTitleNotEmptyFromLeft, 0px);
$selectionListTitleNotEmptyLabelActiveOptionsFontSize: var(--selectionListTitleNotEmptyLabelActiveOptionsFontSize, 11px);
$selectionListTitleNotEmptyLabelActiveOptionsFontWeight: var(--selectionListTitleNotEmptyLabelActiveOptionsFontWeight, 300);

$selectionListToggleArrowFromTop: var(--selectionListToggleArrowFromTop, 8px);
$selectionListToggleArrowFromRight: var(--selectionListToggleArrowFromRight, 10px);
$selectionListToggleArrowHoverBackgrounColor: var(--selectionListToggleArrowHoverBackgrounColor, none !important);

$wrapperBackgroundColor: var(--wrapperBackgroundColor, #fff);
$wrapperBorderRadius: var(--wrapperBorderRadius, 10px);

$skeletonWrapperPadding: var(--skeletonWrapperPadding, 0px 0px 0px 20px);

$searchWrapperMargin: var(--searchWrapperMargin, 10px 0px 0px 0px);
$searchBarMargin: var(--searchBarMargin, 5px 0px 0px 0px);

$addLayerBorderRadius: var(--addLayerBorderRadius, 0 0 6px 6px);
$addLayerFromTop: var(--addLayerFromTop, 55px);
$addLayerFromLeft: var(--addLayerFromLeft, -1px);
$addLayerFromBackgroundColor: var(--addLayerFromBackgroundColor, white);
$addLayerBorder: var(--addLayerBorder, 1px solid #d0d5dd);

$sumWrapperPadding: var(--sumWrapperPadding, 20px 20px 20px 16px);
$sumWrapperColor: var(--sumWrapperColor, #6b6b6b);
$sumWrapperFontSize: var(--sumWrapperFontSize, 15px);

$optionsWrapperHeight: var(--optionsWrapperHeight, 400px);
$optionsWrapperDecreasedHeight: var(--optionsWrapperDecreasedHeight, 300px);
$optionsWrapperOptionPadding: var(--optionsWrapperOptionPadding, 8px 8px 8px 16px);
$optionsWrapperOptionMargin: var(--optionsWrapperOptionMargin, 0px 0px 3px 0px);
$optionsWrapperOptionHoverBackgroundColor: var(--optionsWrapperOptionHoverBackgroundColor, #f2f2f2);
$optionsWrapperOptionActiveBackgroundColor: var(--optionsWrapperOptionActiveBackgroundColor, #e2e2e2);
$optionsWrapperOptionHighlightBackgroundColor: var(--optionsWrapperOptionHighlightBackgroundColor, #f0f9ff);

$resultsCounterPadding: var(--resultsCounterPadding, 10px 0px 10px 20px);
$resultsCounterColor: var(--resultsCounterColor, #6b6b6b);
$resultsCounterFontSize: var(--resultsCounterFontSize, 10px);

$noResultsPadding: var(--noResultsPadding, 10px 0px 10px 20px);
$noResultsColor: var(--noResultsColor, #6b6b6b);
$noResultsFontSize: var(--noResultsFontSize, 10px);
$noResultsHeight: var(--noResultsHeight, 400px);

$selectionListErrorBorder: var(--selectionListErrorBorder, 1px solid #fb6340);
$selectionListActiveBorder: var(--selectionListActiveBorder, 1px solid #2dce89);

$selectionListDisabledLabelColor: var(--selectionListDisabledLabelColor, #d0d5dd);
$selectionListDisabledSelectionListOpacity: var(--selectionListDisabledSelectionListOpacity, 0.5);

$selectionListLabelFromTop: var(--selectionListLabelFromTop, -12px);
$selectionListLabelFromLeft: var(--selectionListLabelFromLeft, 10px);
$selectionListLabelFontSize: var(--selectionListLabelFontSize, 12px);
$selectionListLabelBackgroundColor: var(--selectionListLabelBackgroundColor, white);
$selectionListLabelPadding: var(--selectionListLabelPadding, 0 6px);
$selectionListLabelColor: var(--selectionListLabelColor, #7c7c7c);
