.rdrDay {
	box-sizing: inherit;
	// width: calc(100% / 7);
	width: 36px;
	position: relative;
	font: inherit;
	cursor: pointer;
}

.rdrDayNumber {
	display: block;
	position: relative;
	span {
		color: #1d2429;
	}
}

.rdrDayDisabled {
	cursor: default;
}

@supports (-ms-ime-align: auto) {
	.rdrDay {
		flex-basis: 14.285% !important;
	}
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
	pointer-events: none;
}

.rdrInRange {
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
	pointer-events: none;
}

.rdrDayHovered {
}

.rdrDayActive {
}
