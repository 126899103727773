:root {
	--tableBarLoaderFromTop: 40px;
	--tableTableThBackgroundColor: #ffffff;
	--tableTableTrBg: #f8f8f8;
	--tableTableTrHeight: 47px;
	--tableBodyTextColor: #636363;
	--tableBodyTextFontWeight: 400;
	--tableTableThFontWeight: 600;
	--tableTableThBorderBottom: 1px solid #bcbcbc;
	--tableTableThBorderRight: 1px solid rgba(0, 0, 0, 0.125);
	--tableTableTdBorderRight: 1px solid rgba(0, 0, 0, 0.125);
	--tableTableTdBorderBottom: 1px solid rgba(0, 0, 0, 0.125);
	--tableTableWrapperBorder: 1px solid rgba(0, 0, 0, 0.125);
	--tableSummaryFromTop: 40px;
}