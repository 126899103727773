@import './search-filter.default.scss';
@import '../table.default.scss';
@import '@monorepo/base/src/styles/keyframes/slide-in-up';

.wrapper {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	
	.searchBox {
		display: flex;
		align-items: center;
		margin: 0 5px;
		height: 100%;

		.searchIcon {
			position: absolute;
			display: flex;
			align-items: center;
			padding-left: $searchFilterSearchIconPadding;
			margin-left: 7px;
		}
		.input {
			font-size: $searchFilterInputFontSize;
			color: $searchFilterInputColor;
			padding: $searchFilterInputPadding;
			border: $searchFilterInputBorder;
			background-color: $searchFilterInputBackgroundColor;
			color: $tableBodyTextColor;
			outline: $tableInputsColor;
			width: $searchFilterInputWidth;
			height: 90%;

			padding: $searchFilterInputPadding;
			border-radius: $searchFilterInputBorderRadius;
			transition: 0.2s;

			border: $searchFilterInputHoverBorder;
			border-width: 1px;
			border-color: #d0d5dd;

		}

		.closeIcon {
			position: absolute;
			right: 10px;
		}
	}
}
