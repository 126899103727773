$dropdownWrapperMaxWidth: var(--dropdownWrapperMaxWidth, 400px);
$dropdownWrapperMaxHeight: var(--dropdownWrapperMaxHeight, 40px);
$dropdownWrapperBackgroundColor: var(--dropdownWrapperBackgroundColor, white);
$dropdownWrapperBorderRadius: var(--dropdownWrapperBorderRadius, 6px);
$dropdownWrapperBorder: var(--dropdownWrapperBorder, 1px solid #d0d5dd);
$dropdownWrapperFontSize: var(--dropdownWrapperFontSize, 14px);
$dropdownWrapperColor: var(--dropdownWrapperColor, #474747);
$dropdownWrapperMargin: var(--dropdownWrapperMargin, 30px 0px 0px 0px);

$dropdownArrowIcon: var(--dropdownArrowIcon, #344054);

$dropdownErrorBorder: var(--dropdownErrorBorder, 1px solid #fb6340);

$dropdownActiveBorder: var(--dropdownActiveBorder, 1px solid #2dce89);
$dropdownActiveBoxShadow: var(--dropdownActiveBoxShadow, none);

$dropdownActiveLabelOpacity: var(--dropdownActiveLabelOpacity, 1);

$dropdownWrapperDisabledBackgroundColor: var(--dropdownWrapperDisabledBackgroundColor, white);
$dropdownWrapperDisabledBorder: var(--dropdownWrapperDisabledBorder, 1px solid #d0d5dd);

$dropdownDisabledLabelColor: var(--dropdownDisabledLabelColor, #d0d5dd);
$dropdownDisabledBackgroundColor: var(--dropdownDisabledBackgroundColor, white);
$dropdownDisabledOpacity: var(--dropdownDisabledOpacity, 0.5);

$dropdownLabelFromTop: var(--dropdownLabelFromTop, -12px);
$dropdrownLabelFromLeft: var(--dropdrownLabelFromLeft, 10px);
$dropdownLabelFontSize: var(--dropdownLabelFontSize, 12px);
$dropdownLabelBackgroundColor: var(--dropdownLabelBackgroundColor, white);
$dropdownLabelPadding: var(--dropdownLabelPadding, 0 6px);
$dropdownLabelColor: var(--dropdownLabelColor, #7c7c7c);
$dropdownLabelOpacity: var(--dropdownLabelOpacity, 1);

$dropdownSearchbarMargin: var(--dropdownSearchbarMargin, 5px 0px 0px 0px);
$dropdownSearchbarPadding: var(--dropdownSearchbarPadding, 12px 8px 8px 8px);

$dropdownPadding: var(--dropdownPadding, 10px 10px 10px 12px);
$dropdownFocusBorder: var(--dropdownFocusBorder, none);
$dropdownFocusBoxShadow: var(--dropdownFocusBoxShadow, none);

$dropdownBackgroundColor: var(--dropdownBackgroundColor, white);
$dropdownFromLeft: var(--dropdownFromLeft, 0px);
$dropdownFromTop: var(--dropdownFromTop, 10px);
$dropdownMargin: var(--dropdownMargin, 1px 0px 0px 0px);
$dropdownBorder: var(--dropdownBorder, 1px solid #d0d5dd);
$dropdownBorderRadius: var(--dropdownBorderRadius, 4px);
$dropdownMaxHeight: var(--dropdownMaxHeight, 130px);
$dropdownPadding: var(--dropdownPadding, 0);
$dropdownOpacity: var(--dropdownOpacity, 0);

$dropdownOptionPadding: var(--dropdownOptionPadding, 8px 8px 8px 16px);
$dropdownOptionMargin: var(--dropdownOptionMargin, 0px 0px 3px 0px);
$dropdownOptionHoverBackgroundColor: var(--dropdownOptionHoverBackgroundColor, #f2f2f2);
$dropdownOptionActiveBackgroundColor: var(--dropdownOptionActiveBackgroundColor, #e2e2e2);
$dropdownOptionHighlightBackgroundColor: var(--dropdownOptionHighlightBackgroundColor, #f0f9ff);

$dropdownSeperatorLineMargin: var(--dropdownSeperatorLineMargin, 10px 0px);
$dropdownExtraHeight: var(--dropdownExtraHeight, 350px);
$dropdownDropdownBarDisabledColor: var(--dropdownDropdownBarDisabledColor, #344054);

$dropdownOptionActiveDisabledColor: var(--dropdownOptionActiveDisabledColor, #344054);
$dropdownOptionActiveArrowDisabledColor: var(--dropdownOptionActiveArrowDisabledColor, #98a2b3);
