@import '@monorepo/base/src/styles/devices';
@import '@monorepo/base/src/styles/keyframes/opacity-in-out';
@import '@monorepo/base/src/styles/resets/button';
@import './toast.default.scss';

.content {
	margin: $toastMargin;
	opacity: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: $toastBackgroundColor;
	position: relative;
	padding: $toastPadding;
	height: $toastHeight;
	width: 100%;
	max-width: $toastMaxWidth;
	border-radius: $toastBorderRadius;
	box-shadow: $toastBoxShadow;
	animation: opacityIn 0.2s ease-in;

	@media (max-width: $mobile) {
		width: 95%;
		margin: 0px auto 15px auto;
	}

	.type {
		height: 100%;
		display: flex;
		align-items: center;
		padding: $toastTypePadding;
	}

	.msg {
		padding: $toastMsgPadding;
		width: 100%;

		> div {
			&:first-child {
				font-weight: $toastMsgFirstChildFontWeight;
				color: $toastMsgFirstChildColor;
			}

			&:nth-child(2) {
				font-weight: $toastMsgSecondChildFontWeight;
				color: $toastMsgSecondChildColor;
				font-size: $toastMsgSecondChildFontSize;
				margin: $toastMsgSecondChildMargin;
			}
		}
	}

	.close {
		@include resetBtn;
		cursor: pointer;
		position: relative;
		height: 100%;
		padding: $toastClosePadding;
		position: relative;
		display: flex;
	}
}
