@import '../table.default.scss';
@import './page-size.default.scss';

.wrapper {
	> span {
		font-size: $pageSizeSpanFontSize;
		color: $tableBodyTextColor;
	}
	> select {
		border-color: $tableInputsColor;
		color: $tableBodyTextColor;
		font-size: $pageSizeSelectFontSize;
		border-width: $pageSizeSelectBorderWidth;
		outline: $tableInputsColor;
		padding: $pageSizeSelectPadding;
		border-radius: $pageSizeSelectBorderRadius;
		margin: $pageSizeSelectMargin;
	}
}
