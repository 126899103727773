@import './http-error-modal.default.scss';

.wrapper {
	padding: $httpErrorModalWrapperPadding;

	.title {
		font-size: $httpErrorModalTitleFontSize;
		font-weight: $httpErrorModalTitleFontWeight;
		margin: $httpErrorModalTitleMargin;
	}

	.subtitle {
		font-size: $httpErrorModalSubtitleFontSize;
		font-weight: $httpErrorModalSubtitleFontWeight;
		margin-bottom: $httpErrorModalSubtitleMargin;
	}

	.list {
		font-size: $httpErrorModalListFontSize;

		> li {
			list-style: $httpErrorModalListStyle;
		}
	}
}
