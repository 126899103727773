@import '@monorepo/base/src/styles/resets/button';
@import '@monorepo/base/src/styles/keyframes/opacity-in-out';
@import '@monorepo/base/src/styles/devices';
@import './buttons.default.scss';

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.baseButton {
	cursor: pointer;
	white-space: pre;
}

.rotateIcon {
	width: $rotateIconWidth;
	height: $rotateIconHeight;
	transform-origin: center;
	animation: rotate 1s ease-in-out infinite;
}

.textButton {
	@include resetBtn;
	display: flex;
	align-items: center;
	font-size: $textButtonFontSize;
	font-weight: $textButtonFontWeight;
	line-height: $textButtonLineHeight;

	// width: 100%;
	// height: 100%;

	&.primaryText {
		color: $primaryTextButtonColor;
		height: $primaryTextButtonHeight;
		width: $primaryTextButtonWidth;
	}

	&.secondaryText {
		color: $secondaryTextButtonColor;
		height: $secondaryTextButtonHeight;
		width: $secondaryTextButtonWidth;

		&:hover {
			background-color: $secondayTextHoverBackgroundColor;
		}
	}

	&.unclickable {
		cursor: default;
	}
}

.iconToTheLeft {
	margin-right: $iconToTheLeftPaddingRight;
}

.iconToTheRight {
	margin-left: $iconToTheRightPaddingLeft;
}

.secondary {
	display: flex;
	height: $secondaryButtonHeight;
	justify-content: center;
	background-color: $secondaryButtonBackgroundColor;
	transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
	transition-duration: 200ms;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	color: $secondaryButtonColor;
	font-size: $secondaryButtonFontSize;
	font-weight: $secondaryButtonFontWeight;
	line-height: $secondaryButtonLineHeight;
	padding: $secondaryButtonPadding;
	text-align: center;
	align-items: center;
	width: 100%;
	border-radius: $secondaryButtonBorderRadius;
	border: $secondaryButtonBorder;
	&:hover {
		transition: 0.2s ease-in-out;
		background-color: $secondaryButtonHoverBackgroundColor;
		color: $secondaryButtonHoverColor;
	}
}

.primary {
	display: flex;
	height: $primaryButtonHeight;
	padding: $primaryButtonPadding;
	background-color: $primaryButtonBackgroundColor;
	transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
	transition-duration: 200ms;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	color: $primaryButtonColor;
	text-align: center;
	justify-content: center;
	align-items: center;
	width: 100%;
	font-size: $primaryButtonFontSize;
	font-weight: $primaryButtonFontWeight;
	line-height: $primaryButtonLineHeight;
	border-radius: $primaryButtonBorderRadius;
	border: $primaryButtonBorder;

	&:hover {
		background-color: $primaryButtonHoverBackgroundColor;
	}
}

.large {
	height: $primaryButtonHeight;
}
.medium {
	height: calc(#{$primaryButtonHeight} - 6px);
}
.small {
	height: calc(#{$primaryButtonHeight} - 12px);
}

.disabled {
	cursor: not-allowed !important;
	opacity: $disabledOpacity;
}

// @media (max-width: $largerDesktop) {
// 	.baseButton {

// 	}
	
// 	.rotateIcon {
// 		width: 20px;
// 		height: 20px;
// 		animation: rotate 1s ease-in-out infinite;
// 	}
	
// 	.textButton {
// 		@include resetBtn;
// 		display: flex;
// 		align-items: center;
// 		font-size: 14px;
	
// 		&.primaryText {

// 		}
	
// 		&.secondaryText {
// 			// height: 30px;
	
// 			&:hover {
// 			}
// 		}
	
// 		&.unclickable {
// 		}
// 	}
	
// 	.iconToTheLeft {
// 		padding-right: 3px
// 	}
	
// 	.iconToTheRight {
// 		padding-left: 3px
// 	}
	
// 	.secondary {
// 		// height: 30px; //30px
// 		font-size: $secondaryButtonFontSize;
// 		line-height: $secondaryButtonLineHeight;
// 		padding: $secondaryButtonPadding;
	
// 		&:hover {

// 		}
// 	}
	
// 	.primary {
// 		height: 30px;
// 		padding: $primaryButtonPadding;
// 		font-size: $primaryButtonFontSize;
// 		line-height: $primaryButtonLineHeight;
	
// 		&:hover {
// 		}
// 	}
	

// 	.large {
// 		height: 30px;
// 	}
// 	//relative to large height
// 	.medium {
// 		height: calc(30px - 6px);
// 	}
// 	//relative to large height
// 	.small {
// 		height: calc(30px - 12px);
// 	}
	
// 	.disabled {

// 	}
// }
