@import 'src/styles/theme';

.wrapper {
	display: flex;
	align-items: center;
}

.list {
	max-height: 335px !important;

	.openInNewTab {
		cursor: pointer;
		color: $primaryColor;
		padding: 3px;
		border-radius: 3px;
	}
}

.breadcrumbWrapper {
	display: flex;
	align-items: center;
	position: relative;

	.skeletons {
		width: 140px;
		height: 50px;

		.topSkeleton {
			width: 150px;
		}

		.bottomSkeleton {
			width: 300px;
		}
	}

	.next {
		font-size: 25px;
		font-weight: 100;
		margin: 0px 15px;
		color: #dadce0;
		position: absolute;
		right: 0;
	}
}