$paginationPaginationButtonPadding: var(--paginationPaginationButtonPadding, 10px); 
$paginationPaginationButtonFontSize: var(--paginationPaginationButtonFontSize, 12px); 
$paginationPaginationButtonWidth: var(--paginationPaginationButtonWidth, 36px); 
$paginationPaginationButtonHeight: var(--paginationPaginationButtonHeight, 36px); 
$paginationPaginationButtonMargin: var(--paginationPaginationButtonMargin, 0 3px); 
$paginationPaginationButtonPadding: var(--paginationPaginationButtonPadding, 0); 
$paginationPaginationButtonColor: var(--paginationPaginationButtonColor, #8898aa); 
$paginationPaginationButtonBorder: var(--paginationPaginationButtonBorder, 1px solid #dee2e6); 
$paginationPaginationButtonBackgroundColor: var(--paginationPaginationButtonBackgroundColor, #fff); 
$paginationPaginationButtonHoverBackgroundColor: var(--paginationPaginationButtonHoverBackgroundColor, #dee2e6); 
$paginationEndFontSize: var(--paginationEndFontSize, 20px); 
$paginationPaginationButtonBorderRadius: var(--paginationPaginationButtonBorderRadius, 50%); 
