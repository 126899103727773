$tableFiltersFiltersWrapperGap: var(--tableFiltersFiltersWrapperGap, 5px);
$tableFiltersFiltersGap: var(--tableFiltersFiltersGap, 10px);
$tableFiltersFiltersRowGap: var(--tableFiltersFiltersRowGap, 2px);
$tableFiltersFilterComponentMenuFromTop: var(--tableFiltersFilterComponentMenuFromTop, 20px);
$tableFiltersFilterPadding: var(--tableFiltersFilterPadding, 1px 8px 1px 10px);
$tableFiltersFilterLineHeight: var(--tableFiltersFilterLineHeight, 30px);
$tableFiltersFilterColor: var(--tableFiltersFilterColor, #6941c6);
$tableFiltersFilterFontWeight: var(--tableFiltersFilterFontWeight, 500);
$tableFiltersFilterBackgroundColor: var(--tableFiltersFilterBackgroundColor, #f9f5ff);
$tableFiltersFilterFontSize: var(--tableFiltersFilterFontSize, 13px);
$tableFiltersFilterBorder: var(--tableFiltersFilterBorder, 1px solid #bdbde980);
$tableFiltersFilterHoverBackgroundColor: var(--tableFiltersFilterHoverBackgroundColor, #f4ebff);
$tableFiltersLabelCirclePadding: var(--tableFiltersLabelCirclePadding, 0 0 0 0 !important);
$tableFiltersLabelCircleMargin: var(--tableFiltersLabelCircleMargin, 0 6px 0 0);
$tableFiltersLabelCircleWidth: var(--tableFiltersLabelCircleWidth, 24px);
$tableFiltersLabelCircleFontWeight: var(--tableFiltersLabelCircleFontWeight, 500);
$tableFiltersLabelCircleColor: var(--tableFiltersLabelCircleColor, white);
$tableFiltersLabelCircleBackgroundColor: var(--tableFiltersLabelCircleBackgroundColor, #026aa2);
$tableFiltersFilterContentGap: var(--tableFiltersFilterContentGap, 4px);
$tableFiltersClearIconMargin: var(--tableFiltersClearIconMargin, 0 0 0 5px);
$tableFiltersMenuFromTop: var(--tableFiltersMenuFromTop, 40px);
$tableFiltersMenuPadding: var(--tableFiltersMenuPadding, 0px);
$tableFiltersFilterBorderRadius: var(--tableFiltersFilterBorderRadius, 8px);
$tableFiltersFilterContentMaxWidth: var(--tableFiltersFilterContentMaxWidth, 600px);