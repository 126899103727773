.wrapper {
	> div {
		margin-top: 16px;
	}
}

.domainmDropdownWrapper {
	max-width: 400px;
	margin-top: 20px;
}

.domainmParentSelectWrapper {
	position: absolute;
	top: 65px;
}

.campaignName {
	flex: 2;
}

.campaignId {
	flex: 1;
}

.inputsWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1rem;
}

.checkboxesWrapper {
	.checkboxWrapper {
		margin-top: 20px;
		display: flex;

		.checkboxTitle {
			font-size: 14px;
		}
	}
}

.performancePixelTypeWrapper {
	max-width: 398px;
	margin-top: 15px;
}