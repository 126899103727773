@import 'src/styles/theme';
@import '@monorepo/base/src/styles/text/overflow';

.wrapper {
	display: flex;
	align-items: center;
	position: relative;

	.userBar {
		display: flex;
		align-items: center;
		cursor: pointer;
		width: 50px;
		height: 50px;
		justify-content: center;

		.initialsCircle {
			color: #475467;
			font-style: normal;
			font-weight: 500;
			width: 80%;
			height: 80%;
			border-radius: 50%;
			text-align: center;
			justify-content: center;
			border-color: black;
			border: 2px solid #f2f4f7;

			.initialsText {
				position: relative;
				top: 25%;
				transform: translateY(-50%);
			}
		}

		&:hover {
			background-color: $hoverLinksBg;
		}
	}

	.menu {
		overflow-x: hidden;

		.userMenu {
			padding: 20px 12px 0 20px;
			max-width: 325px;
			min-width: 275px;

			.details {
				margin-bottom: 2px;

				.fullName {
					max-width: 250px;
					@include text-overflow;
					font-weight: 600;
					color: #344054;
					line-height: 20px;
					margin: 0;
				}

				.email {
					max-width: 250px;
					@include text-overflow;
					line-height: 20px;
					font-size: 14px;
					color: #475467;
					font-weight: 400;
				}

				.actingAs {
					max-width: 250px;
					@include text-overflow;
					line-height: 20px;
					font-size: 12px;
					color: #475467;
					font-weight: 400;
				}
			}
		}

		.actions {
			.action {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 5px;
				cursor: pointer;
				height: 50px;
				width: 100%;
				padding-left: 22px;

				&:hover {
					background-color: $hoverLinksBg;
				}

				.icon {
					font-size: 18px;
					color: #344054;
				}

				.text {
					color: #344054;
					line-height: 20px;
					font-weight: 500;
					font-size: 14px;
				}

				.termsAndPrivacy {
					display: flex;

					.icon {
						right: -3px;
						top: -1px;
						position: relative;
						font-size: 11px;
						color: #344054;
						opacity: 0.6;
					}
				}
			}
		}
	}
}
