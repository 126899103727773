@import './skeleton.default.scss';

.rectangle {
	height: $skeletonRectangleHeight;
	border-radius: 8px;
	background-color: $skeletonRectangleBackgroundColor;
	margin: $skeletonRectangleMargin;

	&:first-child {
		margin: $skeletonRectangleFirstChildMargin;
	}

	&.full {
		width: 100% !important;
	}
}

@for $i from 0 to 15 {
	.rectangle:nth-child(#{$i}) {
		width: random(75) / 100 * 100%;
		min-width: 30%;
	}
}
