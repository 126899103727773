.wrapper {
	padding-bottom: 20px !important;
}
.bidRange {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	gap: 1rem;

	> div {
		width: 180px;
	}

	> span {
		margin-top: 20px;
	}
}
