@import 'src/styles/theme';

@keyframes opacityIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	width: 1000px;
	gap: 10px;

	.disclaimer {
		color: #475467;
		font-weight: 500;
		font-size: 12px;
		position: relative;
		top: 10px;
	}

	.textareaWrapper {
		box-shadow: none;
		border: none;
		max-width: 700px;

		textarea {
			box-shadow: var(--inputBoxShadow, 0px 1px 2px rgba(16, 24, 40, 0.05)) !important;
			border: var(--inputBorder, 1px solid #d0d5dd) !important;
		}
	}

	.analayzeBtn {
		width: 100px;
	}

	.errorMsg {
		display: flex;
		align-items: center;
		gap: 5px;
		min-width: 300px;
		max-width: 400px;
		height: 40px;
		font-size: 14px;
		padding: 10px;
		line-height: 16px;
		color: #fb6340;
		border-radius: 7px;
		animation: opacityIn 0.2s ease-in forwards;
	}

	.table {
		width: 580px;
		border-collapse: separate;
		border-spacing: 0 12px;

		.headers {
			text-align: left;
		}

		.checkbox {
			width: 30px;
		}

		.bidkeyName {
			width: 500px;
			padding: 0 5px;
		}

		.status {
			width: 80px;
			padding: 0 5px;
		}
	}
}

.selectedCounter {
	color: $successColor;
	font-size: 13px;
}
