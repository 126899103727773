$loadingLineFromTop: var(--loadingLineFromTop, 0px);
$loadingLineFromLeft: var(--loadingLineFromLeft, 0px);
$loadingLineFromBottom: var(--loadingLineFromBottom, auto);
$loadingLineFromRight: var(--loadingLineFromRight, auto);
$loadingLineHeight: var(--loadingLineHeight, 2px);
$loadingLineDuration: var(--loadingLineDuration, 1s);

$loadingLineInnerFromTop: var(--loadingLineInnerFromTop, 0px);
$loadingLineInnerFromLeft: var(--loadingLineInnerFromLeft, 0px);
$loadingLineInnerFromBottom: var(--loadingLineInnerFromBottom, auto);
$loadingLineInnerFromRight: var(--loadingLineInnerFromRight, auto);
$loadingLineInnerHeight: var(--loadingLineInnerHeight, 2px);
$loadingLineInnerBackgroundColor: var(--loadingLineInnerBackgroundColor, #6941c6);

$loadingLineInnerTransparentOpacity: var(--loadingLineInnerTransparentOpacity, 0);
$loadingLineInnerColorOpacity: var(--loadingLineInnerColorOpacity, 1);