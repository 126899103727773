@import '@monorepo/base/src/styles/keyframes/opacity-in-out';
@import 'src/styles/theme';



.debugPageWrappaer {
	padding: 50px 0px 50px 0px;

	.listAndTools {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding-top: 80px;

		.titleAndToolBarWrapper {
			position: fixed;
			top: 50px;
			width: calc(100% - var(--openWidth, 260px));
			z-index: 2;
			display: flex;
			flex-direction: column;

			.titleAndToolBar {
				padding: 10px;
				background-color: $primaryColor;
				display: flex;
				justify-content: space-between;

				.title {
					color: white;
					font-size: 24px;
					font-weight: bold;
				}

				.toolBar {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 10px;


					.tagTextInput {
						padding: 5px;
						background-color: $primaryColor;
						color: #dddddd;
						border: 1px white solid;
						border-radius: 5px;
					}

					.showBorderButton {
						background-color: $primaryColor;
						border-width: 0;
						height: 100%;
						color: #fff;
						border-radius: 5px;
						padding: 0 10px;
						cursor: pointer;

						&:hover {
							background-color: darken($primaryColor, 5%);
						}

						&.active {
							background-color: darken($primaryColor, 5%);

							&:hover {
								background-color: darken($primaryColor, 4%);
							}
						}
					}

					.displayCodeButton {
						background-color: $primaryColor;
						border-width: 0;
						height: 100%;
						border-radius: 5px;
						padding: 0 10px;

						&:hover {
							background-color: darken($primaryColor, 5%);
						}

						&.active {
							background-color: darken($primaryColor, 5%);

							&:hover {
								background-color: darken($primaryColor, 4%);
							}
						}
					}

					.colorPicker {
						width: 24px;
						height: 24px;
						border-radius: 5px;
						background-color: #fff;
						border: none;
						outline: none;
						cursor: pointer;
						transition: transform 0.2s ease-in-out;

						&:hover {
							transform: scale(1.1);
						}
					}
				}
			}

			.codeInput {
				max-width: 100%;
				padding: 10px;
				height: 150px;
				max-height: 50%;
				background-color: rgb(30, 30, 30);
				color: white;
				font-size: 14px;
				line-height: 1.4;
				text-align: start;
				white-space: nowrap;
				font-family: monospace;
				box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
			}

		}

		.divider {
			margin: 40px 0;
			width: min-content;
			text-wrap: nowrap;
			padding: 10px 20px;
			border-radius: 5px;
			z-index: 1;
			display: flex;
			justify-content: space-between;

			.title {
				color: rgb(0, 0, 0);
				text-decoration: underline;
				font-size: 24px;
			}
		}

		.responsiveWrapper {
			height: 80%;
			width: 80%;
			display: flex;
			align-items: center;
			justify-content: center;

			.creativeResizeableWrapper {
				height: 300px;
				width: 300px;
				min-height: calc(1rem + 50px);
				min-width: calc(1rem + 50px);
				resize: both;
				overflow: auto;
				padding: 1rem;

				.creativeResponsiveIframe {
					height: calc(100% - .5rem);
					width: calc(100% - .5rem);
					border: none;

					&.showBorder {
						outline: 1px solid;
						height: calc(100% - .5rem + 1px);
						width: calc(100% - .5rem + 1px);
					}
				}
			}

		}


		.creativeOptions {

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			gap: 20px;

			.creativeListItem {
				overflow: hidden;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border-radius: 5px;

				.creativeIframeWrapperColumn {
					padding: 20px;
					display: flex;
					flex-direction: column;
					align-items: center;

					.creativeListItemTitleTop {
						font-size: 20px;
						font-weight: bold;
						color: $titleColor;
					}

					.creativeIframeWrapperRow {
						display: flex;
						flex-direction: row;
						align-items: center;

						.creativeListItemTitleLeft {
							font-size: 20px;
							font-weight: bold;
							color: $titleColor;
							rotate: 270deg;
							z-index: 0;
						}

						.creativeIframe {
							border: none;
							vertical-align: bottom;

							&.showBorder {
								outline: 1px solid $titleColor;
							}
						}
					}
				}
			}
		}
	}
}