$navPageMaxWidth: var(--navPageMaxWidth, calc(100% - var(--navWidth)));
$navInPagePadding: var(--navInPagePadding, 10px 0px 0px 0px);

$navWidth: var(--navWidth, 300px);
$navMinWidth: var(--navMinWidth, 300px);
$navMaxWidth: var(--navMaxWidth, 300px);

$navRelativeWrapper: var(--navRelativeWrapper, 380px);
$navRelativeWrapperNavPadding: var(--navRelativeWrapperNavPadding, 0px 10px 0px 0px);
$navRelativeWrapperNavMargin: var(--navRelativeWrapperNavMargin, 100px 0px 0px 0px);

$navGroupPadding: var(--navGroupPadding, 0px 5px 5px 0px);
$navGroupBorderTop: var(--navGroupBorderTop, 1px solid rgba(0, 0, 0, 0.12));
$navGroupTogglerBorderRadius: var(--navGroupTogglerBorderRadius, 0px 10px 10px 0px);
$navGroupTogglerMargin: var(--navGroupTogglerMargin, 5px 0px 0px 0px);
$navGroupTogglerHoverBackgroundColor: var(--navGroupTogglerHoverBackgroundColor, #e6e6e6);

$navGroupTitleFontSize: var(--navGroupTitleFontSize, 14px);
$navGroupTitleFontWeight: var(--navGroupTitleFontWeight, 500);
$navGroupTitlePadding: var(--navGroupTitlePadding, 5px 5px 5px 20px);
$navGroupTitleColor: var(--navGroupTitleColor, #1d1d1d);

$navGroupNavLinkPadding: var(--navGroupNavLinkPadding, 5px 5px 5px 30px !important);

$navItemFontSize: var(--navItemFontSize, 14px);
$navItemFontWeight: var(--navItemFontWeight, 500);
$navItemMargin: var(--navItemMargin, 0px 0px 9px 0px);
$navItemBorderRadius: var(--navItemBorderRadius, 0px 10px 10px 0px);
$navItemColor: var(--navItemColor, #1d1d1d);
$navItemBackgroundColor: var(--navItemBackgroundColor, #e6e6e6);
$navItemHoverBackgroundColor: var(--navItemHoverBackgroundColor, #f3f4f6);

$navItemActiveRoutedColor: var(--navItemActiveRoutedColor, #6366f1);
$navItemActiveRouteBackgroundColor: var(--navItemActiveRouteBackgroundColor, #e2e2ff80 !important);

$navItemLinkPadding: var(--navItemLinkPadding, 5px 5px 5px 20px);

$navListColor: var(--navListColor, #6b6b6b);
$navListItemBorderRadius: var(--navListItemBorderRadius, 2px);
$navListItemMargin: var(--navListItemMargin, 0px 0px 0px 0px);
$navListItemLineHeight: var(--navListItemLineHeight, 27px);
$navListItemChildrenFontSize: var(--navListItemChildrenFontSize, 14px);
$navListItemChildrenPadding: var(--navListItemChildrenPadding, 4px 5px 4px 15px);